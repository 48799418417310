import { LOCATION_AND, LOCATION_IOS } from "service/constant"
import imgIntroTit from "assets/images/main_intro_title.png"
import imgIntroFrame from "assets/images/main_intro_frame.png"

const MainIntro = () => {
  return (
    <section className="main_sec_wrap sec_intro">
      <div className="wrap_wide">
        <section>
          <section className="main_intro_desc">
            <div className="desc">
              <h4 className="img">
                <img src={imgIntroTit} alt="" />
              </h4>
              <ul className="intro_func_list">
                <li>
                  <strong>
                    위험별
                    <br className="break_pc_off" /> 단계알람
                  </strong>
                </li>
                <li>
                  <strong>가족피싱 보호하기</strong>
                </li>
                <li>
                  <strong>가족피싱 보호받기</strong>
                </li>
                <li>
                  <strong>
                    메신저
                    <br className="break_pc_off" /> 피싱보호
                  </strong>
                </li>
                <li>
                  <strong>
                    뱅킹 앱<br className="break_pc_off" /> 알림
                  </strong>
                </li>
                <li>
                  <strong>주요피싱 유형</strong>
                </li>
                <li>
                  <strong>최신피싱 사례</strong>
                </li>
                <li>
                  <strong>
                    악성앱
                    <br className="break_pc_off" /> 검사
                  </strong>
                </li>
              </ul>
            </div>
            <div className="app_down break_pc_off">
              <a href={LOCATION_AND} target="_blank" rel="noreferrer" className="google">
                Google Play
              </a>
              <a href={LOCATION_IOS} target="_blank" rel="noreferrer" className="ios">
                App Store
              </a>
            </div>
          </section>
          <section className="main_intro_img">
            <span className="img frame">
              <img src={imgIntroFrame} alt="" />
            </span>
            <span className="img shield"></span>
            <span className="img balloon"></span>
            <span className="img lock"></span>
            <span className="img gradient"></span>
            <span className="img phone"></span>
            <span className="img like"></span>
            <span className="img location"></span>
          </section>
        </section>
      </div>
    </section>
  )
}

export default MainIntro

import { Link } from "react-router-dom"
import { LOCATION_BLOG, LOCATION_DATAU } from "service/constant"

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer_wrap">
        <ul>
          <li>
            <a href={LOCATION_DATAU} target="_blank" rel="noopener noreferrer">
              회사소개
            </a>
          </li>
          <li>
            <Link to={"/terms/used"}>이용약관</Link>
          </li>
          <li>
            <Link to={"/terms/personal"}>개인정보처리방침</Link>
          </li>
          <li>
            <a href={LOCATION_BLOG} target="_blank" rel="noopener noreferrer">
              서비스 블로그
            </a>
          </li>
        </ul>
        <p>
          (주)데이터유니버스 대표 : 강원석 | 서울 영등포구 국제금융로2길 32
          <br />
          고객지원센터 :1811-7531 (공휴일 휴무 09:00 ~ 12:00 / 13:00 ~ 18:00)
          <br />
          Copyright © 2018 DataUniverse. All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer

export const OWRS_LIVE = "https://owrs.antiscam.co.kr"
export const OWRS_DEV = "https://dev-owrs.antiscam.co.kr"
export const IMAGE_SERV = "https://images.antiscam.co.kr/"

export const LOCATION_AND = "https://play.google.com/store/apps/details?id=com.datauniverse.antiscam"
export const LOCATION_IOS =
  "https://apps.apple.com/kr/app/%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%94%BC%EC%8B%B1%EB%B3%B4%ED%98%B8-%EB%B3%B4%EC%9D%B4%EC%8A%A4%ED%94%BC%EC%8B%B1-%EC%9D%98%EC%8B%AC%EB%B2%88%ED%98%B8-%EC%88%98%EC%8B%A0-%EC%8B%9C-%EB%B0%9C%EC%8B%A0%EC%9E%90-%EB%B2%88%ED%98%B8/id1452978615"
export const LOCATION_DATAU = "http://datauniverse.co.kr/"
export const LOCATION_BLOG = "https://blog.naver.com/datau2"

export const GOOGLE_REACPTCHA_KEY = "6LfY-64UAAAAAKRauxtOB-meShZ7e5_kFKNoLs_0"
export const KAKAO_DEV_KEY = "bc951a3379957948f164a0928420bea8"

export const CMDTYPE_MAIN_HOMEPOP = "POPUP"
export const CMDTYPE_MAIN_PHISHING = "MainPhishingData"
export const CMDTYPE_MAIN_PHISHING_DETAIL = "MainPhishingDataDetail"
export const CMDTYPE_NEW_PHISHING = "NewPhishingData"
export const CMDTYPE_NOTICE = "Notice"
export const CMDTYPE_NOTICE_DETAIL = "NoticeDetail"
export const CMDTYPE_NEWS = "NEWS"
export const CMDTYPE_FAQ = "FAQ"
export const CMDTYPE_FAQ_DETAIL = "FAQDETAIL"
export const CMDTYPE_GET_CAPTCHA = "GETCAPTCHA"
export const CMDTYPE_SEND_CAPTCHA = "SENDOTPCAPTCHA"
export const CMDTYPE_SEND_VERIFY = "SENDOTPVERFY"
export const CMDTYPE_PERSONAL_DETAIL = "PersonalDetail"
export const CMDTYPE_USED_DETAIL = "UsedDetail"
export const CMDTYPE_QNA_REGIST = "QNAREGIST"
export const CMDTYPE_CASE_SHARE = "CASESHARINGWEB"
export const CMDTYPE_GET_TEMRS = "GetTerms"
export const CMDTYPE_CHECK_MVNO_MEMBER = "CheckMvnoMember"
export const CMDTYPE_GET_REFUND_TEMRS = "GetRefundTerms"
export const CMDTYPE_REFUND_SEND_OTP = "RefundSendOTP"
export const CMDTYPE_REFUND_SERVICE_CANCEL = "RefundServiceCancel"

export const SERVICE_CANCEL_HOST = "servicecancel"

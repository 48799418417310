import LinklineTab from "components/LinklineTab"
import SubpageConts from "layouts/SubpageConts"
import SubpageTop from "layouts/SubpageTop"
import { Outlet } from "react-router-dom"

const linkProps = [
  { key: 0, tabname: "피해대응안내", link: "guide" },
  { key: 1, tabname: "피해사례공유", link: "share" },
]

const Respond = () => {
  return (
    <>
      <SubpageTop />
      <SubpageConts>
        <LinklineTab linkArray={linkProps} />
        <Outlet />
      </SubpageConts>
    </>
  )
}

export default Respond

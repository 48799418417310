import { FocusEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useIndexButton, useInput, useTimer } from "hooks"
import { useGetCaptchaQueryMutate, useRefundTermsData, useVerifyQueryMutate } from "hooks/query"
import {
  deviceCheck,
  isURLIncludeServiceCancel,
  layerToggle,
  validInputIsString,
  validInputLength,
  validPhoneNumber,
} from "service/utils"
import { CMDTYPE_GET_CAPTCHA, CMDTYPE_REFUND_SEND_OTP, CMDTYPE_REFUND_SERVICE_CANCEL } from "service/constant"
import { SubmitButton } from "components/ContsButton"
import { InsertInput, InsertSelect } from "components/InsertForms"
import { CaptchaPopup, InfoPopup, NoticePopup, TermsPop } from "components/Popup"
import { useNavigate } from "react-router-dom"
import "styles/page/refund.scss"
import { CheckForms, CheckFormsList, InsertInputInnerChild } from "components/Refund"
import { useRecoilValue } from "recoil"
import { isPcState } from "store"
import { RefundResponseType } from "types"

const popupText = {
  3: "인증번호 3회 오류<br />인증번호를 다시 받아주세요.",
  7: "인증번호가 성공적으로 전송됐습니다.",
  8: "인증번호를 입력해 주세요.",
  10: "필수 약관에 동의 해주세요.",
  11: "인증번호 받기를 먼저 시도해 주세요.",
  12: "유효 인증시간이 초과되었습니다.<br />인증번호를 다시 받아주세요.",
  13: "정상적으로 해지처리 되었습니다.<br />이용해 주셔서 감사합니다.",
  16: "휴대폰 번호 또는 통신사를 확인하세요.",
  17: "서비스 해지가 완료되었습니다.<br />가입 당일 해지 고객은 요금이 부과되지 않습니다.",
} as const

type popupTextType = keyof typeof popupText

const bankOptions = [
  { value: "국민은행", name: "국민은행" },
  { value: "기업은행", name: "기업은행" },
  { value: "농협은행", name: "농협은행" },
  { value: "우리은행", name: "우리은행" },
  { value: "신한은행", name: "신한은행" },
  { value: "하나은행", name: "하나은행" },
  { value: "한국씨티은행", name: "한국씨티은행" },
  { value: "SC제일은행", name: "SC제일은행" },
  { value: "경남은행", name: "경남은행" },
  { value: "광주은행", name: "광주은행" },
  { value: "대구은행", name: "대구은행" },
  { value: "도이치은행", name: "도이치은행" },
  { value: "부산은행", name: "부산은행" },
  { value: "BNP파리바은행", name: "BNP파리바은행" },
  { value: "산림조합", name: "산림조합" },
  { value: "산업은행", name: "산업은행" },
  { value: "새마을금고", name: "새마을금고" },
  { value: "수출입은행", name: "수출입은행" },
  { value: "수협은행", name: "수협은행" },
  { value: "신협", name: "신협" },
  { value: "우체국", name: "우체국" },
  { value: "저축은행", name: "저축은행" },
  { value: "전북은행", name: "전북은행" },
  { value: "제주은행", name: "제주은행" },
  { value: "중국건설은행", name: "중국건설은행" },
  { value: "중국공상은행", name: "중국공상은행" },
  { value: "중국은행", name: "중국은행" },
  { value: "지역농축협", name: "지역농축협" },
  { value: "카카오뱅크", name: "카카오뱅크" },
  { value: "케이뱅크", name: "케이뱅크" },
  { value: "토스뱅크", name: "토스뱅크" },
  { value: "BOA(뱅크오브아메리카)", name: "BOA(뱅크오브아메리카)" },
  { value: "HSBC은행", name: "HSBC은행" },
  { value: "JP모간체이스은행", name: "JP모간체이스은행" },
]

const Refund = () => {
  const navigate = useNavigate()
  const [popIdx, onChangePopIdx] = useIndexButton(0)

  const [captchaNumber, onChangeCaptchaNumber, setCaptchaNumber] = useInput("", "number")
  const [cert, onChangeCert, setCert] = useInput("", "number")

  const [min, sec, time, timeRef, startTimer, resetTimer] = useTimer()
  const [bankOpt, onChangeBankOpt, setBankOpt] = useInput("")
  const [name, onChangeName, setName] = useInput("")
  const [phone, onChangePhone, setPhone] = useInput("", "number")
  const [account, onChangeAccount, setAccount] = useInput("", "account")
  const [termsAgree, setTermsAgree] = useState(0)
  const [showTerms, setShowTerms] = useState(false)

  const phoneInput = useRef<HTMLInputElement>(null)
  const certInput = useRef<HTMLInputElement>(null)
  const certButtonRef = useRef<HTMLButtonElement>(null)
  const captchaButtonRef = useRef<HTMLButtonElement>(null)
  const capchaInputRef = useRef<HTMLInputElement>(null)
  const refundButtonRef = useRef<HTMLButtonElement>(null)

  const { data: captchaData, mutate: getCaptcha, reset: resetCaptcha } = useGetCaptchaQueryMutate()
  const {
    data: refundSendOtpVerifyData,
    mutate: refundSendOtpVerify,
    reset: resetRefundSendOtpVerify,
  } = useVerifyQueryMutate()
  const { data: refundVerifyData, mutate: refundVerify, reset: resetRefundVerify } = useVerifyQueryMutate()

  const { data: RefundTermsData } = useRefundTermsData()
  const isPc = useRecoilValue(isPcState)

  const resetPopIdx = useCallback(() => {
    layerToggle()
    onChangePopIdx(0)
    if (popIdx === 14 || popIdx === 15 || popIdx === 17) window.location.reload()
  }, [popIdx, onChangePopIdx])

  const resetRefundData = useCallback(() => {
    setBankOpt("")
    setName("")
    setPhone("")
    setAccount("")
    setTermsAgree(0)
  }, [setBankOpt, setName, setPhone, setAccount, setTermsAgree])

  const resetCallback = useCallback(() => {
    resetRefundData()
    setCaptchaNumber("")
    setCert("")
    resetCaptcha()
    resetRefundSendOtpVerify()
    resetRefundVerify()
  }, [resetRefundData, setCaptchaNumber, setCert, resetCaptcha, resetRefundSendOtpVerify, resetRefundVerify])

  const resetCallbackExceptMutateData = useCallback(() => {
    resetRefundData()
    setCaptchaNumber("")
    setCert("")
    resetCaptcha()
  }, [resetRefundData, setCaptchaNumber, setCert, resetCaptcha])

  const onChangeTermsAgree = useCallback(() => {
    setTermsAgree((prevTerms) => (prevTerms === 0 ? 1 : 0))
  }, [])
  const toggleTermsPop = useCallback(() => {
    layerToggle()
    setShowTerms((prevShow) => !prevShow)
  }, [])
  const isActiveInquiryButton = useCallback(() => {
    return bankOpt &&
      refundSendOtpVerifyData?.Body.TransactionID &&
      !validInputLength(name, 2) &&
      validPhoneNumber(phone) &&
      !validInputLength(account, 10) &&
      termsAgree
      ? false
      : true
  }, [bankOpt, refundSendOtpVerifyData?.Body.TransactionID, name, phone, account, termsAgree])

  const onCaptchaSend = useCallback(() => {
    const phoneElm = phoneInput.current
    const buttonElm = certButtonRef.current
    if (phoneElm) phoneElm.blur()
    if (buttonElm) buttonElm.blur()
    getCaptcha(CMDTYPE_GET_CAPTCHA, {
      onSuccess: () => {
        setCaptchaNumber("")
        layerToggle()
        onChangePopIdx(4)
      },
    })
  }, [onChangePopIdx, setCaptchaNumber, getCaptcha])

  const onCaptchaSubmit = useCallback(() => {
    const inputEle = capchaInputRef?.current
    const buttonEle = captchaButtonRef?.current
    if (buttonEle) buttonEle.blur()
    if (inputEle) inputEle.blur()
    if (captchaNumber.length < 4) {
      alert("보안문자 입력을 확인해주세요.")
      return false
    }
    refundSendOtpVerify(
      {
        CmdType: CMDTYPE_REFUND_SEND_OTP,
        Body: {
          CaptchaTransID: captchaData?.Body.TransID,
          TeleType: "2",
          PNumber: phone,
          AuthType: "delete",
          AuthToken: captchaNumber,
          Pcode: "ec9a7230-7725",
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
        },
      },
      {
        onSuccess: (data) => {
          if (data.Header.ErrCode !== 0) {
            if (data.Header.ErrCode === 103003) {
              // 환불대기자
              onChangePopIdx(15)
              resetTimer(resetCallbackExceptMutateData)
              return
            } else if (data.Header.ErrCode === 100505) {
              alert(data.Header.ErrMsg)
            } else {
              alert(data.Header.ErrMsg)
              setCaptchaNumber("")
            }

            switch (data.Header.ErrCode) {
              case 100401: // 3분이내 발송이력
                resetPopIdx()
                break
              case 100301: // 비가입자
              case 100503: // 보안문자 3회 오류
                window.location.reload()
                break
              case 100504: // 요청정보없음
              case 900002: // 휴대폰 통신사 맞지않음
              case 102501: // LGU 점검
                resetTimer(resetCallback)
                resetPopIdx()
                break
              case 103001: // SKT/KT 가입자
              case 103004: // 이용이력 있음
                resetPopIdx()
                break
              case 103002: // 미가입자
                window.location.reload()
                break
              case 103003: // 환불대기자
                break
              case 100505: // 보안문자 일치하지 않습니다. (%d/3)
              case 100403: // 이미 서비스에 가입되어 있습니다.
              case 400001: // 서비스를 이용하실 수 없습니다. 고객센터(1811-7531)로 문의 해주세요.
              case 100305: // 알뜰폰 사용자는 앱 설치후 서비스 이용이 가능합니다. '확인'버튼을 누르시면 앱스토어로 이동합니다.
              case 100805: // 만 19세 이하 청소년은 가입 할 수 없습니다.
              case 100806: // 만 15세 이하 청소년은 가입 할 수 없습니다.
              case 900013: // 서비스 이용이 불가능한 단말기입니다.
              case 100310: // 구글 플레이스토어에서 해지하시기 바랍니다.
              case 100311: // 앱스토어에서 해지하시기 바랍니다.
              case 100900:
                break
              default:
                alert("휴대폰 번호를 확인하세요.")
                window.location.reload()
                break
            }
          } else {
            alert("인증번호가 성공적으로 전송됐습니다.")
            resetTimer()
            resetPopIdx()
            startTimer(data.Body.TimeOut)
            const certEle = certInput.current
            if (certEle) certEle.focus()
          }
        },
      },
    )
  }, [
    captchaNumber,
    refundSendOtpVerify,
    captchaData?.Body.TransID,
    phone,
    onChangePopIdx,
    resetTimer,
    resetCallbackExceptMutateData,
    setCaptchaNumber,
    resetPopIdx,
    resetCallback,
    startTimer,
  ])
  const cancelVerifySubmit = useCallback(() => {
    const certEle = certInput.current
    const refundButtonEle = refundButtonRef.current
    if (certEle) certEle.blur()
    if (refundButtonEle) refundButtonEle.blur()

    if (!refundSendOtpVerifyData?.Body.TransactionID) {
      layerToggle()
      onChangePopIdx(11)
      return false
    }
    if (phone.length < 10) {
      layerToggle()
      onChangePopIdx(16)
      return false
    }
    if (cert.length < 4) {
      layerToggle()
      onChangePopIdx(8)
      return false
    }

    refundVerify(
      {
        CmdType: CMDTYPE_REFUND_SERVICE_CANCEL,
        Body: {
          TeleType: "2",
          PNumber: phone,
          AuthType: "delete",
          Pcode: "046f13e8-8897",
          TransactionID: refundSendOtpVerifyData?.Body.TransactionID,
          CryptoNumber: cert,
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
          ReqRefundData: {
            BankName: bankOpt,
            Accountnumber: account,
            AccountName: name,
            TermsAgree: true,
          },
        },
      },
      {
        onSuccess: (data) => {
          if (data.Header.ErrCode !== 0) {
            onChangePopIdx(9)
            // setCert("")
            if (data.Header.ErrCode === 100503) window.location.reload()
          } else {
            if (data.Body.UseDay === 0) {
              // 당일해지 무료고지 팝업
              resetTimer(resetCallbackExceptMutateData)
              onChangePopIdx(17)
              return
            }
            resetTimer(resetCallbackExceptMutateData)
            onChangePopIdx(14)
          }
        },
      },
    )
  }, [
    refundSendOtpVerifyData?.Body.TransactionID,
    phone,
    cert,
    refundVerify,
    bankOpt,
    account,
    name,
    onChangePopIdx,
    // setCert,
    resetTimer,
    resetCallbackExceptMutateData,
  ])

  const onBlurForValidationCert = (e: FocusEvent<HTMLInputElement>) => validInputLength(e.target.value, 4)
  const onBlurForValidationAccount = (e: FocusEvent<HTMLInputElement>) => validInputLength(e.target.value, 8)

  const onBlurForValidationString = (e: FocusEvent<HTMLInputElement>) =>
    validInputLength(e.target.value, 2) || validInputIsString(e.target.value)

  const refundResultContent = (refund: RefundResponseType) =>
    `이용해 주셔서 감사합니다.<br /><br />{${refund.Amount}}원이 영업일 7일 이내 입금될 예정입니다.<br />※ 계좌번호, 예금주 등 입력하신 환불정보에 문제가 있을 경우 환불이 지연될 수 있습니다.<br /><br />입력하신 정보: ${refund.BankName}/${refund.Accountnumber}/${refund.AccountName}`

  useEffect(() => {
    if (time.current < 0) {
      layerToggle()
      onChangePopIdx(12)
      resetTimer(resetCallback)
    }
  }, [sec, time, onChangePopIdx, resetTimer, resetCallback])
  useEffect(() => {
    let timeRefValue = 0
    if (timeRef.current) timeRefValue = timeRef.current
    return () => {
      clearInterval(timeRefValue)
    }
  }, [timeRef])

  useLayoutEffect(() => {
    if (!isURLIncludeServiceCancel()) {
      return navigate(-1)
    }
  }, [navigate])

  return (
    <>
      <h3 className="subconts_tit">서비스 환불</h3>
      <section className="forms_wrap_refund">
        <section className="refund_guide_section">
          <div className="refund_guide_text_container">
            <span className="title">서비스 환불을 위해 본인인증이 필요합니다.</span>
            <br />
            <br />
            서비스 환불을 신청하기 전에 아래 내용을 꼭 확인해 주세요 .
            <br />
            <span className="warning">* 서비스 이용 이력이 없을 경우 환불이 가능합니다.</span>
            <br />* 환불 신청 시 서비스는 자동으로 해지됩니다.
          </div>
        </section>
        {isPc ? (
          <InsertInput
            title="휴대폰 번호"
            isValid={validPhoneNumber(phone)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={11}
            holder="‘-’ 제외 하고 입력해 주세요."
            readTo={refundSendOtpVerifyData?.Body.TransactionID ? true : false}
            Inputref={phoneInput}
            value={phone}
            changeFunc={onChangePhone}
            keyboardFunc={onCaptchaSend}
          >
            <SubmitButton
              buttonRef={certButtonRef}
              text="인증번호 받기"
              role="submit"
              isValid={!validPhoneNumber(phone)}
              clickFunc={onCaptchaSend}
            />
          </InsertInput>
        ) : (
          <>
            <InsertInput
              title="휴대폰 번호"
              isValid={validPhoneNumber(phone)}
              type={deviceCheck() ? "tel" : "text"}
              maxLength={11}
              holder="‘-’ 제외 하고 입력해 주세요."
              readTo={refundSendOtpVerifyData?.Body.TransactionID ? true : false}
              Inputref={phoneInput}
              value={phone}
              changeFunc={onChangePhone}
              keyboardFunc={onCaptchaSend}
            />
            <div className="cont_btns_wrap" style={{ margin: "12px 0px 24px 0px" }}>
              <SubmitButton
                buttonRef={certButtonRef}
                text="인증번호 받기"
                role="submit"
                isValid={!validPhoneNumber(phone)}
                clickFunc={onCaptchaSend}
              />
            </div>
          </>
        )}

        <InsertInputInnerChild
          title="인증번호 입력"
          type={deviceCheck() ? "tel" : "text"}
          maxLength={4}
          holder="인증번호를 입력해주세요."
          Inputref={certInput}
          value={cert}
          changeFunc={onChangeCert}
          blurFunc={onBlurForValidationCert}
          errMsg="인증문자 입력을 확인해주세요."
          keyboardFunc={cancelVerifySubmit}
        >
          <span className="timer">
            {min}:{sec}
          </span>
        </InsertInputInnerChild>
        <InsertSelect
          title="환불 받을 은행 선택"
          selectOptions={bankOptions}
          defaultValue={{ value: "", name: "은행을 선택해주세요." }}
          value={bankOpt}
          changeFunc={onChangeBankOpt}
        />
        <InsertInput
          title="계좌번호 입력"
          isValid={!validInputLength(account, 8)}
          type="text"
          maxLength={16}
          holder="계좌번호를 입력해주세요."
          value={account}
          changeFunc={onChangeAccount}
          blurFunc={onBlurForValidationAccount}
          errMsg="계좌번호를 정확히 입력해주세요."
        />
        <InsertInput
          title="예금주명 입력"
          isValid={!validInputLength(name, 2) && !validInputIsString(name)}
          type="text"
          maxLength={20}
          holder="예금주명을 입력해주세요."
          value={name}
          blurFunc={onBlurForValidationString}
          changeFunc={onChangeName}
          errMsg="이름을 정확히 입력해주세요."
        />
        <CheckFormsList>
          <CheckForms
            checkId="terms"
            text={"개인정보 수집 및 이용동의(필수)"}
            value={termsAgree}
            changeFunc={onChangeTermsAgree}
            changeTerms={toggleTermsPop}
          />
        </CheckFormsList>
        <div className="cont_btns_wrap" style={{ padding: "0 0" }}>
          <SubmitButton
            text="서비스 해지 후 환불 등록"
            role="submit"
            isValid={isActiveInquiryButton()}
            clickFunc={cancelVerifySubmit}
          />
        </div>
        <div className="refund_caution">
          *가입 당일 해지하신 고객님은 요금이 청구되지 않습니다.
          <br />
          서비스 이용요금은 매월 사용일 수 만큼 계산되어 청구됩니다.
        </div>
      </section>
      {popIdx === 4 && captchaData && (
        <CaptchaPopup
          title="하단 보안문자를 입력하세요."
          captchaBodyData={captchaData.Body}
          captchaReset={onCaptchaSend}
          closeFunc={resetPopIdx}
        >
          <InsertInput
            Inputref={capchaInputRef}
            isValid={!validInputLength(captchaNumber, 4)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={4}
            holder="보안문자 입력."
            value={captchaNumber}
            changeFunc={onChangeCaptchaNumber}
            blurFunc={onBlurForValidationCert}
            keyboardFunc={onCaptchaSubmit}
            errMsg="보안문자 입력을 확인해주세요."
          />
          <SubmitButton text="취소" role="submit" clickFunc={resetPopIdx} />
          <SubmitButton buttonRef={captchaButtonRef} text="확인" role="main" clickFunc={onCaptchaSubmit} />
        </CaptchaPopup>
      )}
      {(popIdx === 14 || popIdx === 15) && (
        <NoticePopup
          title="서비스 해지 및 환불 접수 완료"
          contents={
            popIdx === 14
              ? refundResultContent(refundVerifyData?.Body)
              : refundResultContent(refundSendOtpVerifyData?.Body)
          }
          closeFunc={resetPopIdx}
        >
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </NoticePopup>
      )}
      {(popIdx === 3 ||
        popIdx === 7 ||
        popIdx === 8 ||
        popIdx === 10 ||
        popIdx === 11 ||
        popIdx === 12 ||
        popIdx === 17) && (
        <InfoPopup info={popupText[popIdx as popupTextType]} closeFunc={resetPopIdx}>
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </InfoPopup>
      )}
      {popIdx === 9 && (
        <InfoPopup info={refundVerifyData?.Header.ErrMsg} closeFunc={resetPopIdx}>
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </InfoPopup>
      )}
      {showTerms && (
        <TermsPop
          termsObject={{
            title: decodeURIComponent(escape(window.atob(RefundTermsData?.Body[0].LgupTitle))),
            desc: decodeURIComponent(escape(window.atob(RefundTermsData?.Body[0].LgupTerms))),
          }}
          closeFunc={toggleTermsPop}
        >
          <SubmitButton text="확인" role="submit" clickFunc={toggleTermsPop} />
        </TermsPop>
      )}
    </>
  )
}

export default Refund

import styled, { css } from "styled-components"

export const CheckListWrap = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div {
    flex-grow: 1;
    flex-basis: 33.3%;
    margin-top: 24px;
    padding-left: 12px;
    &:nth-child(3n + 1) {
      padding-left: 0;
      @media all and (max-width: 1200px) {
        padding-left: 12px;
      }
    }
    @media all and (max-width: 1200px) {
      margin-top: 16px;
      flex-basis: 50%;
      &:nth-child(odd) {
        padding-left: 0;
      }
    }
  }
`

export const CheckBox = styled.div<{ isAct: boolean }>`
  display: inline-block;
  position: relative;
  padding: 0 30px;
  > label {
    display: block;
    cursor: pointer;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  span {
    margin-left: 8px;
    font-size: 13px;
    @media all and (max-width: 540px) {
      margin-left: 0;
    }
  }
  .icon {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    border-radius: 50%;
    border: 2px solid transparent;
    @media all and (max-width: 1200px) {
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }
    ${(props) =>
      props.isAct
        ? css`
            border-color: #272e40;
          `
        : css`
            border-color: #ced6e3;
          `}
    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 6px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -6px 0 0 -6px;
      border-left: 2px solid transparent;
      border-bottom: 2px solid transparent;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      ${(props) =>
        props.isAct
          ? css`
              border-color: #272e40;
            `
          : css`
              border-color: #ced6e3;
            `}
    }
  }
  .terms_btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    font-size: 13px;
    color: #3dc289;
    text-decoration: underline;
    z-index: 1;
  }
`

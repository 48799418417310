import { useDepFlag } from "hooks"
import { Link } from "react-router-dom"
import { isURLIncludeServiceCancel } from "service/utils"

const NavPc = () => {
  const [depFlag, onChangeDepFlag] = useDepFlag([
    { id: 0, flag: false },
    { id: 1, flag: false },
  ])

  return (
    <nav id="nav">
      <div className="nav_wrap">
        <ul className="dep1_list">
          <li className="dep1">
            <Link to={"/join"} className="dep1_tit">
              서비스가입
            </Link>
          </li>
          <li
            className={`dep1 ${depFlag[0].flag ? "entered" : ""}`}
            onMouseEnter={() => onChangeDepFlag(0)}
            onMouseLeave={() => onChangeDepFlag(0)}
          >
            <strong className="dep1_tit">고객지원</strong>
            <div className="dep2">
              <ul className="dep2_list">
                <li>
                  <Link to={"/customer/notice"}>공지사항</Link>
                </li>
                <li>
                  <Link to={"/customer/news"}>새소식</Link>
                </li>
                <li>
                  <Link to={"/customer/inquiry"}>1:1문의</Link>
                </li>
                {isURLIncludeServiceCancel() && (
                  <li>
                    <Link to={"/customer/refund"}>서비스 환불</Link>
                  </li>
                )}
                <li>
                  <Link to={"/customer/faq"}>자주묻는질문</Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`dep1 ${depFlag[1].flag ? "entered" : ""}`}
            onMouseEnter={() => onChangeDepFlag(1)}
            onMouseLeave={() => onChangeDepFlag(1)}
          >
            <strong className="dep1_tit">피해대응안내</strong>
            <div className="dep2">
              <ul className="dep2_list">
                <li>
                  <Link to={"/respond/guide"}>피해대응안내</Link>
                </li>
                <li>
                  <Link to={"/respond/share"}>피해사례공유</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="dep1">
            <Link to={"/cancel"} className="dep1_tit">
              서비스해지
            </Link>
          </li>
          <li className="dep1">
            <Link to={"/phishing"} className="dep1_tit">
              피싱사례
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default NavPc

import { NewsDataType } from "types"
import { useMoreButton } from "hooks"
import { useNewsQueryData } from "hooks/query"
import { CommonButton } from "components/ContsButton"

const News = () => {
  const [more, onChangeMore] = useMoreButton(0)
  const { data: newsData } = useNewsQueryData(7, more)
  const newsSpliceData = newsData?.Body.splice(6 * (more + 1), more + 1)

  return (
    <>
      <h3 className="subconts_tit">새소식</h3>
      <section className="news_wrap">
        {newsData?.Body.map((newsItem: NewsDataType) => {
          return (
            <div className="news_conts" key={newsItem.Idx}>
              <a href={newsItem.LinkPath} target="_blank" rel="noreferrer">
                <strong className="news_tit">{newsItem.NewsTitle}</strong>
                <span className="news_date">{newsItem.RegDT}</span>
              </a>
            </div>
          )
        })}
      </section>
      {newsSpliceData?.length !== 0 && (
        <div className="cont_btns_wrap">
          <CommonButton text="더보기" clickFunc={onChangeMore} />
        </div>
      )}
    </>
  )
}

export default News

import { useCallback, useState } from "react"
import { SubmitButton } from "components/ContsButton"
import { BankPop } from "components/Popup"
import { GUIDE_CONTS, GUIDE_BANK_LIST } from "service/constant/guide"
import { layerToggle } from "service/utils"

const Guide = () => {
  const [showBankPopup, setShowBankPopup] = useState(false)
  const [bankName, setBankName] = useState("")
  const [bankTel, setBankTel] = useState("")

  const toggleBankPop = useCallback(() => {
    layerToggle()
    setShowBankPopup((prevState) => !prevState)
  }, [])
  const onChangeSelectBank = useCallback(
    (bank: string, tel: string) => {
      setBankName(bank)
      setBankTel(tel)
      toggleBankPop()
    },
    [toggleBankPop],
  )

  return (
    <>
      <h3 className="subconts_tit">피해대응안내</h3>
      <section className="guide_wrap">
        <div className="subpage_info">
          <pre>
            막상 피싱 사기 피해가 발생하면 피해 금액을 돌려받기 어려운 경우가 많습니다. 그래서 ‘스마트피싱보호’를 통한
            예방이 최선입니다. 만약 실제 피해를 보셨다면 아래와 같은 절차로 대응하세요.
          </pre>
        </div>
        <section className="guide_caller">
          <div>
            <span className="img caller"></span>
            <ul className="call_list">
              <li>
                <strong>지급정지 · 피해신고</strong>
                <span>▶ 경찰청 : 국번없이 112</span>
              </li>
              <li>
                <strong>피싱사이트 신고</strong>
                <span>▶ 인터넷진흥원 : 국번없이 118</span>
              </li>
              <li>
                <strong>피해상담 및 환급</strong>
                <span>▶ 금융감독원 : 국번없이 1332</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="guide_respond">
          <h4>대응요령</h4>
          <div className="guide_respond_conts">
            <ol>
              {GUIDE_CONTS.map((guideCont) => {
                return guideCont.idx === 1 ? (
                  <li className="respond_cont show" key={guideCont.idx}>
                    <span className={`img respond_icon icon${guideCont.idx}`}></span>
                    <div className="respond_desc">
                      <strong>{guideCont.title}</strong>
                      <p>{guideCont.text}</p>
                      <section className="respond_way">
                        <strong>[지금계좌 정지방법]</strong>
                        <p>
                          1. 피해금을 송금/이체한 계좌 또는 사기 이용 계좌를 관리하는 금융회사에 지급정지를 요청하세요.
                        </p>
                      </section>
                      <section className="respond_call">
                        <strong>[주요은행 콜센터 번호]</strong>
                        <div className="bank_call_selector">
                          <button className="bank_choice" onClick={toggleBankPop}>
                            {bankName ? bankName : "은행선택"}
                          </button>
                          <input
                            className="bank_callnumber"
                            placeholder="콜센터 번호를 조회할 은행을 선택해주세요."
                            readOnly
                            defaultValue={bankTel}
                          />
                        </div>
                        <pre>
                          2. 금융회사가 피해자의 인적 사항, 피해내역 및 신청 사유를 파악하기 위해 피해자가 지급정지를
                          요청한 날부터 3영업일 이내에 피해구제신청서 및 피해자의 신분증 사본 등을 서면으로 금융회사에
                          제출하세요.
                          <br />
                          <br />
                          <strong className="alert">
                            ※유선 신청 후 3영업일이 경과하고 추가로 14일 내 서면 신청서를 제출하지 않으면 지급정지가
                            종료되어 피해금을 환급받지 못하는 사례가 발생할 수 있습니다.
                          </strong>
                          <br />
                          <br />※ 지급정지 종료 및 피해구제신청과 관련된 자세한 사항은 유선지급정지를 신청한 금융회사로
                          문의해 주시기 바랍니다.
                        </pre>
                      </section>
                    </div>
                  </li>
                ) : (
                  <li className={`respond_cont ${guideCont.idx === 4 ? "" : "show"} `} key={guideCont.idx}>
                    <span className={`img respond_icon icon${guideCont.idx}`}></span>
                    <div className="respond_desc">
                      <strong>{guideCont.title}</strong>
                      <p>{guideCont.text}</p>
                    </div>
                  </li>
                )
              })}
            </ol>
          </div>
        </section>
      </section>
      {showBankPopup && (
        <BankPop bankList={GUIDE_BANK_LIST} changeFunc={onChangeSelectBank} closeFunc={toggleBankPop}>
          <SubmitButton text="닫기" role="submit" clickFunc={toggleBankPop} />
        </BankPop>
      )}
    </>
  )
}

export default Guide

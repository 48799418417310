import { ReactNode } from "react"
import { CheckBox, CheckListWrap } from "./styles"

interface CheckFormsType {
  checkId: string
  termsIdx?: number
  value: number
  text: string
  changeFunc: (termsIdx: number) => void
  changeTerms?: (termIdx?: number | undefined) => void
}

export const CheckForms = ({ checkId, termsIdx, value, text, changeFunc, changeTerms }: CheckFormsType) => {
  return (
    <div>
      <CheckBox isAct={Boolean(value)}>
        <input
          type="checkbox"
          className="hide"
          id={checkId}
          name={checkId}
          checked={Boolean(value)}
          onChange={() => changeFunc(termsIdx as number)}
        />
        <label htmlFor={checkId}>
          <i className="icon" />
          <span>{text}</span>
        </label>
        {changeTerms && (
          <button type="button" className="terms_btn" onClick={() => changeTerms(termsIdx)}>
            보기
          </button>
        )}
      </CheckBox>
    </div>
  )
}

export const CheckFormsList = ({ children }: { children: ReactNode }) => {
  return <CheckListWrap>{children}</CheckListWrap>
}

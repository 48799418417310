import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { layerClose, onScrollTop } from "service/utils"

const ScrollTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    layerClose()
    onScrollTop(false)
  }, [pathname])

  return null
}

export default ScrollTop

import styled, { css } from "styled-components"

export const InsertWrap = styled.section<{ isValid?: boolean }>`
  margin-top: 60px;
  &:first-child {
    margin-top: 0;
  }
  @media all and (max-width: 1200px) {
    margin-top: 36px;
  }
`

export const InsertTitle = styled.div<{ isValid?: boolean }>`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-weight: 700;
    font-size: 18px;
    @media all and (max-width: 1200px) {
      font-size: 16px;
    }
  }
  .limit {
    font-size: 14px;
    color: #ff2424;
    ${(props) =>
      props.isValid &&
      css`
        color: #3dc289;
      `}
  }
`

export const InsertBox = styled.div`
  display: flex;
  align-items: center;
  .timer {
    font-size: 18px;
    @media all and (max-width: 1200px) {
      font-size: 16px;
    }
  }
`

export const InsertInner = styled.span<{ isValid?: boolean; isSelect?: boolean }>`
  display: block;
  position: relative;
  flex: 1;
  ${({ isSelect }) =>
    isSelect &&
    css`
      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: 24px;
        top: 50%;
        margin-top: -6px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid #272e40;
      }
    `}
  & + * {
    min-width: 160px;
    margin-left: 20px;
    @media all and (max-width: 1200px) {
      min-width: auto;
      margin-left: 7px;
    }
  }
  .picker_button,
  input,
  textarea,
  select {
    display: block;
    width: 100%;
    padding: 16px 22px;
    text-align: left;
    font-size: 18px;
    background-color: #fff;
    border: 1px solid #ced6e3;
    @media all and (max-width: 1200px) {
      padding: 14px 16px;
      font-size: 16px;
    }
    &::placeholder {
      color: #a1aab9;
    }
  }
  input,
  textarea {
    &:read-only {
      background-color: #e7ecf5;
    }
  }
  textarea {
    min-height: 265px;
  }
  select {
    option {
      font-size: 18px;
      @media all and (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  .is_valid {
    position: absolute;
    right: 20px;
    top: 25%;

    span {
      color: #aaa;
      text-align: right;
      font-family: "Noto Sans KR";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media all and (max-width: 540px) {
        font-size: 16px;
      }
    }
  }
  .icon_calendar {
    margin-right: 12px;
  }

  .validate_check_msg {
    position: absolute;
    top: 100%;
    left: 0;
    color: red;
    font-size: 14px;
    margin-top: 4px;
    margin-left: 15px;
  }
`

export const CheckListWrap = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div {
    flex-grow: 1;
    flex-basis: 33.3%;
    padding-left: 12px;
    &:nth-child(3n + 1) {
      padding-left: 0;
      @media all and (max-width: 1200px) {
        padding-left: 12px;
      }
    }
    @media all and (max-width: 1200px) {
      flex-basis: 50%;
      &:nth-child(odd) {
        padding-left: 0;
      }
    }
  }
`

import { Link } from "react-router-dom"
import { useNewsQueryData } from "hooks/query"
import { NewsDataType } from "types"

const MainNews = () => {
  const { data: newsData } = useNewsQueryData(6, 0)

  return (
    <section className="main_sec_wrap sec_news">
      <div className="wrap_wide">
        <div className="main_news_wrap">
          <h4>
            <Link to={"/customer/news"}>
              <strong className="break_pc_off">
                Anti-Scam
                <br />
                News
              </strong>
              <strong className="break_pc_on">Anti-Scam News</strong>
              <span>스마트피싱보호 새소식</span>
            </Link>
          </h4>
          <ol className="news_list">
            {newsData?.Body.map((newsItem: NewsDataType) => {
              return (
                <li key={newsItem.Idx}>
                  <a href={newsItem.LinkPath} target="_blank" rel="noreferrer">
                    <div className="news_list_conts">
                      <span className={`news_type ${newsItem.NewsType === 1 ? "news" : "blog"}`}>
                        {newsItem.NewsType === 1 ? "NEWS" : "BLOG"}
                      </span>
                      <strong className="news_tit">{newsItem.NewsTitle}</strong>
                    </div>
                    <span className="img">
                      <img src={newsItem.ImgPath} alt={newsItem.NewsTitle} />
                    </span>
                  </a>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    </section>
  )
}

export default MainNews

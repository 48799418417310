export const GUIDE_CONTS = [
  {
    idx: 1,
    title: "1. 계좌지급정지",
    text: "보이스피싱으로 인하여 범죄에 이용된 계좌에 송금을 하셨다면, 해당계좌에서 돈이 빠져나가지 않도록 지급정지를 시키는 것이 가장 중요합니다. 계좌에서 돈이 빠져나갈 경우 범죄자가 이미 현금으로 출금하여 도주했을 확률이 매우 높기 때문입니다.  지급정지는 내가 이체한 은행 또는 돈이 이체된 은행 모두 신고가 가능합니다. 최우선적으로 지급 정지를 하시기 바랍니다.",
  },
  {
    idx: 2,
    title: "2. 범죄신고",
    text: "112 또는 경찰청 사이버수사대에 신고를 해야 하며, 필요할 경우 경찰서에 출두하여 신고서를 작성해야 할 수 있습니다. 다만, 이미 보이스피싱 피해가 발생한 상태이므로, 접수된 내용으로 사후 수사가 진행될 뿐 즉시 범인을 검거하기는 현실적으로 어려울 가능성이 높습니다.",
  },
  {
    idx: 3,
    title: "3. 피해금액 환급",
    text: "지급정지 요청을 받은 은행(금융회사)는 지급정지 후 금간원에 채권소멸절차 개시 공고를 요청하게 되며, 금감원의 개시 공고 후 별다른 이의제기 없이 2개월이 경과하면 해당 계좌의 채권이 소멸되고, 금감원이 채권소멸일로부터 14일 이내에 환급금액을 결정하면 은행(금융회사)은 즉시 피해자에게 환급을 하게 됩니다. .다만, 이 같은 환급은 계좌에 돈이 남아있을 경우 가능하며, 해당 계좌의 사기 피해자가 여러 명일 경우 동일한 채권자로 간주되어 환급 가능한 금액이 줄어들 수 있습니다.",
  },
  {
    idx: 4,
    title: "4. 보험금 청구 및 변호사 지문",
    text: "스마트피싱보호 서비스 가입자 분들께서는 사전에 약정된 내용에 따라 최대 300만원까지 보험금을 지급해드립니다. 또한 원만한 해결이 안되어 소송이 필요한 경우 무료로 1회에 한해서 보이스피싱 피해 전문 변호사의 자문을 해드립니다. (소송 수임은 별도)    ",
  },
]

export const GUIDE_BANK_LIST = [
  { classname: "ibk", bankname: "IBK기업은행", tel: "1566-2566, 1588-2588" },
  { classname: "kb", bankname: "KB국민은행", tel: "1588-9999, 1599-9999, 1644-9999" },
  { classname: "kdb", bankname: "KDB산업은행", tel: "1588-1500" },
  { classname: "nonghyop", bankname: "NH농협은행", tel: "1522-3000, 1661-3000" },
  { classname: "sc", bankname: "SC제일은행", tel: "1588-1599" },
  { classname: "sh", bankname: "SH수협은행", tel: "1588-1515, 1644-1515" },
  { classname: "shinhan", bankname: "신한은행", tel: "1577-8000, 1599-8000, 1544-8000" },
  { classname: "shinhyup", bankname: "신협", tel: "1566-6000" },
  { classname: "wori", bankname: "우리은행", tel: "1588-5000, 1599-5000" },
  { classname: "hana", bankname: "하나은행", tel: "1588-1111, 1599-1111" },
  { classname: "citi", bankname: "한국씨티은행", tel: "1588-7000" },
  { classname: "kakao", bankname: "카카오뱅크", tel: "1599-3333" },
  { classname: "k", bankname: "케이뱅크", tel: "1522-1000" },
  { classname: "toss", bankname: "토스뱅크", tel: "1661-7654" },
  { classname: "gyongnam", bankname: "BNK경남은행", tel: "1588-8585, 1600-8585" },
  { classname: "busan", bankname: "BNK부산은행", tel: "1544-6200, 1588-6200" },
  { classname: "daegu", bankname: "DGB대구은행", tel: "1566-5050, 1588-5050" },
  { classname: "kwangju", bankname: "광주은행", tel: "1588-3388, 1600-4000" },
  { classname: "saemaeul", bankname: "새마을금고", tel: "1599-9000" },
  { classname: "ucheguk", bankname: "우체국금융", tel: "1588-1900" },
  { classname: "jeonbuk", bankname: "전북은행", tel: "1588-4477" },
  { classname: "jeju", bankname: "제주은행", tel: "1588-0079" },
  { classname: "localnonghyop", bankname: "지역 농.축협", tel: "1661-2100" },
]

import { useEffect } from "react"
import { mainScrollPostion } from "service/utils"

const MainStop = () => {
  useEffect(() => {
    mainScrollPostion(".main_stop_wrap", 0)
  }, [])

  return (
    <section className="main_sec_wrap sec_stop">
      <div className="wrap_conts">
        <div className="main_stop_wrap">
          <strong className="stop_txt">잠시 멈추고.</strong>
          <strong className="stop_txt">생각한 뒤.</strong>
          <strong className="stop_txt">통화하세요.</strong>
        </div>
      </div>
    </section>
  )
}

export default MainStop

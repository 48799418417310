import { useEffect } from "react"
import { Link } from "react-router-dom"
import { mainScrollPostion } from "service/utils"
import imgFunc01 from "assets/images/main_func01.gif"
import imgFunc02 from "assets/images/main_func02.gif"
import imgFunc03 from "assets/images/main_func03.gif"
import imgFunc04 from "assets/images/main_func04.gif"
import imgFunc05 from "assets/images/main_func05.gif"
import imgFunc06 from "assets/images/main_func06.gif"
import imgFunc07 from "assets/images/main_func07.gif"
import PhishingInquiryImg from "assets/images/phishing_inquiry.png"
import MoPhishingInquiryImg from "assets/images/mo_phishing_inquiry.png"
import FuncCooperateImg from "assets/images/main_func_cooperate.png"
import MoFuncCooperateImg from "assets/images/mo_main_func_cooperate.png"

const MainFunc = () => {
  useEffect(() => {
    mainScrollPostion(".func_item", 500)
  }, [])

  return (
    <section className="main_sec_wrap sec_func">
      <div className="wrap_conts">
        <h4>
          국내 최초 금융사기 예방 서비스<strong>스마트피싱보호</strong>
        </h4>
        <section className="main_func_wrap">
          <ul>
            <li className="func_item">
              <div className="func_desc">
                <i>최신피싱사례</i>
                <strong className="func_tit">
                  최신 피싱 사례를 받아보는
                  <br />
                  가장 빠른 방법
                </strong>
                <p>
                  스마트피싱보호는 빅데이터를 수집, 분석해 최근 발생하는 피싱/금융사기 <br /> 유형을 콘텐츠로
                  제공합니다.
                </p>
              </div>
              <span className="img">
                <img src={imgFunc01} alt="" />
              </span>
            </li>
            <li className="func_item">
              <div className="func_desc">
                <i>주요피싱유형</i>
                <strong className="func_tit">
                  반복되는 피싱 유형만 모아보는
                  <br />
                  똑똑한 방법
                </strong>
                <p>
                  스마트피싱보호는 독자적인 기술로 분석해 특정 시기, 사회 이슈별 반복 <br /> 되는 사기 유형을 카드 뉴스
                  콘텐츠로 제공합니다.
                </p>
              </div>
              <span className="img">
                <img src={imgFunc02} alt="" />
              </span>
            </li>
            <li className="func_item">
              <div className="func_desc">
                <i>피싱의심번호 알림</i>
                <strong className="func_tit">
                  피싱의심전화는 STOP,
                  <br />
                  미리 알고 차단하세요
                </strong>
                <p>
                  피싱에 이용되는 발신 번호 유형을 수집, 분석한 데이터베이스를 기반으로 <br />
                  의심 번호를 사전에 알립니다.
                </p>
              </div>
              <span className="img">
                <img src={imgFunc03} alt="" />
              </span>
            </li>
            <li className="func_item">
              <div className="func_desc">
                <i>가족피싱알림</i>
                <strong className="func_tit">
                  소중한 가족을
                  <br />
                  피싱으로부터 보호하세요
                </strong>
                <p>
                  보호 대상을 등록하면 피싱의심번호와 통화할 때 가족(보호자)에게 알림을
                  <br /> 보내 피해를 막을 수 있습니다.
                </p>
              </div>
              <span className="img">
                <img src={imgFunc04} alt="" />
              </span>
            </li>
            <li className="func_item">
              <div className="func_desc">
                <i>메신저피싱알림</i>
                <strong className="func_tit">
                  지인을 사칭하는 메신저피싱,
                  <br />
                  경고 알림으로 예방하세요
                </strong>
                <p>
                  자주 발생하는 메신저피싱 키워드를 실시간 분석해 피싱 주의 알림을 <br /> 제공합니다.
                </p>
              </div>
              <span className="img">
                <img src={imgFunc05} alt="" />
              </span>
            </li>
            <li className="func_item">
              <div className="func_desc">
                <i>악성앱검사</i>
                <strong className="func_tit">
                  터치 한 번으로
                  <br />
                  악성앱을 탐지하세요
                </strong>
                <p>
                  전화 가로채기, 원격제어 등 사기로부터 쉽게 노출되는 악성앱을 탐지해 <br /> 휴대폰을 안전하게
                  보호하세요.
                </p>
              </div>
              <span className="img">
                <img src={imgFunc06} alt="" />
              </span>
            </li>
            <li className="func_item">
              <div className="func_desc">
                <i>피싱해킹보상보험</i>
                <strong className="func_tit">
                  피싱 사기 피해가 발생해도
                  <br />
                  든든하게 보상 받으세요
                </strong>
                <p>
                  피싱 사기 피해(보이스피싱, 스미싱 등)가 발생한 경우 서비스 가입자 <br /> 1인 1사고 당 보험한도액
                  *300만 원 내 보상받을 수 있습니다. <br />
                  <br /> *자기부담금 별도(60만원), 증권상 최대 보상한도액 초과 시 지급 불가, 사고입증자료 제출 필요
                </p>
              </div>
              <span className="img">
                <img src={imgFunc07} alt="" />
              </span>
            </li>
          </ul>
        </section>
        <section className="main_func_case">
          <strong className="func_tit">
            금융사기 근절을 위한 사회공헌 활동으로&nbsp;
            <br className="break_mobile_off" />
            금융/보안 기관에 컨텐츠를 무상으로 제공합니다.
          </strong>
          <p>
            스마트피싱보호는 매주 2회 보이스피싱, 스미싱 등 최신 금융사기 예방 컨텐츠를 제작해 금융/보안기관에 무상으로
            제공합니다.
            <br />
            컨텐츠 API 연동으로 제휴사 APP 활성화에 기여하고 금융사기로부터 고객을 안전하게 보호하세요.
          </p>
          <span className="img">
            <img src={PhishingInquiryImg} alt="" className="break_pc_off" />
            <img src={MoPhishingInquiryImg} alt="" className="break_pc_on" />
          </span>
          <Link to={"/customer/inquiry"} className="linkto_button">
            금융사기 피해예방 컨텐츠 도입 문의
          </Link>
        </section>
        <section className="main_func_cooperate">
          <strong className="func_tit">제휴 협력사</strong>
          <span className="img">
            <img src={FuncCooperateImg} alt="" className="break_tablet_off" />
            <img src={MoFuncCooperateImg} alt="" className="break_tablet_on" />
          </span>
        </section>
      </div>
    </section>
  )
}

export default MainFunc

import { useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { isPcState } from "store"
import { checkDeviceWidth, kakaoInit } from "service/utils"
import ScrollTop from "layouts/ScrollTop"
import Footer from "layouts/components/Footer"
import Header from "layouts/components/Header"
import Cancel from "views/Cancel"
import Customer from "views/Customer"
import Faq from "views/Customer/Faq"
import Inquiry from "views/Customer/Inquiry"
import News from "views/Customer/News"
import Notice from "views/Customer/Notice"
import NoticeDetail from "views/Customer/Notice/NoticeDetail"
import Join from "views/Join"
import Main from "views/Main"
import Phishing from "views/Phishing"
import PhishingList from "views/Phishing/PhishingList"
import NewPhishingDetail from "views/Phishing/NewPhishingDetail"
import Respond from "views/Respond"
import Guide from "views/Respond/Guide"
import Share from "views/Respond/Share"
import Terms from "views/Terms"
import Used from "views/Terms/Used"
import Personal from "views/Terms/Personal"
import Refund from "views/Customer/Refund"

const App = () => {
  const setIsPc = useSetRecoilState(isPcState)
  useEffect(() => {
    kakaoInit()
    window.addEventListener("resize", () => {
      const checkPc = checkDeviceWidth(window.innerWidth)
      setIsPc(checkPc)
    })
  }, [setIsPc])
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollTop />
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/join" element={<Join />} />
          <Route path="/customer" element={<Customer />}>
            <Route path="notice">
              <Route index element={<Notice />} />
              <Route path=":noticeId" element={<NoticeDetail />} />
            </Route>
            <Route path="news" element={<News />} />
            <Route path="faq" element={<Faq />} />
            <Route path="refund" element={<Refund />} />
            <Route path="inquiry" element={<Inquiry />} />
            <Route path="*" element={<Navigate replace to="notice" />} />
          </Route>
          <Route path="/respond" element={<Respond />}>
            <Route path="guide" element={<Guide />} />
            <Route path="share" element={<Share />} />
            <Route path="*" element={<Navigate replace to="guide" />} />
          </Route>
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/cancel/:telecomCode" element={<Cancel />} />
          <Route path="/phishing" element={<Phishing />}>
            <Route index element={<PhishingList />} />
            <Route path="main/:mainId" element={<PhishingList />} />
            <Route path="new/:newId" element={<NewPhishingDetail />} />
          </Route>
          <Route path="/terms" element={<Terms />}>
            <Route path="used" element={<Used />} />
            <Route path="personal" element={<Personal />} />
            <Route path="*" element={<Navigate replace to="used" />} />
          </Route>
          <Route path="/LGUP/unsubscribe" element={<Navigate replace to="/cancel/2" />} />
          <Route path="/service/cancel" element={<Navigate replace to="/cancel" />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App

import { Link } from "react-router-dom"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { usePhishingQueryData } from "hooks/query"
import { CMDTYPE_MAIN_PHISHING } from "service/constant"
import { MainPhishingDataType } from "types"

const MainPhishing = () => {
  const { data: mainPhishingData } = usePhishingQueryData(CMDTYPE_MAIN_PHISHING)

  return (
    <section className="main_sec_wrap sec_phishing">
      <div className="wrap_conts">
        <section className="phishing_wrap">
          <div className="phishing_desc">
            <strong className="title">주요피싱유형</strong>
            <span>최근 빈번하게 발생하는 주요 피싱유형을 확인해 사전에 피해를 예방하세요.</span>
          </div>
          {mainPhishingData && (
            <>
              <div className="phishing_swiper main break_pc_off">
                <Swiper
                  loop={true}
                  slidesPerView={4}
                  slidesPerGroup={4}
                  spaceBetween={16}
                  modules={[Navigation]}
                  navigation
                >
                  {mainPhishingData?.Body.map((mainPhishingItem: MainPhishingDataType) => {
                    return (
                      <SwiperSlide key={mainPhishingItem.Idx}>
                        <div className="phishing_slide">
                          <Link to={`/phishing/main/${mainPhishingItem.Idx}`}>
                            <span className="img">
                              <img src={mainPhishingItem.CardThumbNail} alt="" />
                            </span>
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
              <div className="phishing_swiper main break_pc_on">
                <Swiper loop={true} spaceBetween={16} modules={[Navigation]} navigation>
                  {mainPhishingData?.Body.map((mainPhishingItem: MainPhishingDataType, mainPhishingIdx: number) => {
                    if ((mainPhishingIdx + 1) % 4 === 0) {
                      const first: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx - 3]
                      const second: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx - 2]
                      const third: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx - 1]
                      const fourth: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx]

                      return (
                        <SwiperSlide key={mainPhishingItem.Idx}>
                          <div className="phishing_slide">
                            <ol className="phishing_slide_innerlist">
                              <li>
                                <Link to={`/phishing/main/${first.Idx}`}>
                                  <span className="img">
                                    <img src={first.CardThumbNail} alt="" />
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to={`/phishing/main/${second.Idx}`}>
                                  <span className="img">
                                    <img src={second.CardThumbNail} alt="" />
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to={`/phishing/main/${third.Idx}`}>
                                  <span className="img">
                                    <img src={third.CardThumbNail} alt="" />
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to={`/phishing/main/${fourth.Idx}`}>
                                  <span className="img">
                                    <img src={fourth.CardThumbNail} alt="" />
                                  </span>
                                </Link>
                              </li>
                            </ol>
                          </div>
                        </SwiperSlide>
                      )
                    }
                    return null
                  })}
                </Swiper>
              </div>
            </>
          )}
        </section>
        <Link to={"/phishing"} className="linkto_button">
          최신피싱사례 보러가기
        </Link>
      </div>
    </section>
  )
}

export default MainPhishing

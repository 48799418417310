import { useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useNoticeDetailQueryData } from "hooks/query"
import { CommonButton } from "components/ContsButton"

const NoticeDetail = () => {
  const { noticeId } = useParams()
  const navigate = useNavigate()
  const { data: detailData } = useNoticeDetailQueryData(noticeId)

  const goList = useCallback(() => {
    navigate("/customer/notice")
  }, [navigate])

  return (
    <>
      <h3 className="subconts_tit">공지사항</h3>
      <div className="notice_detail_wrap">
        <div>
          <div className="detail_tit">
            {detailData?.Body.Title}
            <p className="detail_date">{detailData?.Body.RegDT_Text}</p>
          </div>
          <p className="detail_conts" dangerouslySetInnerHTML={{ __html: detailData?.Body.Contents }} />
        </div>
      </div>
      <div className="cont_btns_wrap">
        <CommonButton text="목록보기" clickFunc={goList} />
      </div>
    </>
  )
}

export default NoticeDetail

import { NavLink } from "react-router-dom"
import { LineTabItem, LineTabWrap } from "./styles"

export interface LinkTabType {
  key: number
  tabname: string
  link: string
}

const LinklineTab = ({ linkArray }: { linkArray: LinkTabType[] }) => {
  return (
    <LineTabWrap>
      <ul>
        {linkArray.map((linkItem) => {
          const { key, tabname, link } = linkItem
          return (
            <LineTabItem key={key}>
              <NavLink to={link} className={({ isActive }) => (isActive ? "active" : "")}>
                {tabname}
              </NavLink>
            </LineTabItem>
          )
        })}
      </ul>
    </LineTabWrap>
  )
}

export default LinklineTab

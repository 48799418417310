import { RefObject, useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useRecoilState, useSetRecoilState } from "recoil"
import { copyURL, onScrollTop } from "service/utils"
import { showAppdownState, showTopState } from "store"
import { AppDown, ButtonCommon, ButtonPhishing, ButtonSubmit, FixTopButton, PhishingButtonList } from "./styles"

interface CommonButtonType {
  clickFunc: () => void
  text: string
}

export type ButtonRole = "submit" | "active" | "main"

export const CommonButton = ({ text, clickFunc }: CommonButtonType) => {
  return <ButtonCommon onClick={clickFunc}>{text}</ButtonCommon>
}

export interface SubmitButtonType extends CommonButtonType {
  role?: ButtonRole
  isValid?: boolean
  /*20230731 phil disabled 옵션 추가*/
  disabled?: boolean
  buttonRef?: RefObject<HTMLButtonElement>
}

export const SubmitButton = ({ text, role, isValid, clickFunc, disabled, buttonRef }: SubmitButtonType) => {
  return (
    <ButtonSubmit ref={buttonRef} role={role} disabled={disabled || (role !== "active" && isValid)} onClick={clickFunc}>
      {text}
    </ButtonSubmit>
  )
}

export interface PhishingButtonType {
  shareKakao: () => void
  mainId?: string
}

export const PhishingButtonWrap = ({ shareKakao, mainId }: PhishingButtonType) => {
  const [showShare, setShowShare] = useState(false)

  const handleShowShare = useCallback(() => {
    setShowShare((prevShowShare) => !prevShowShare)
  }, [])
  const copyPhishingContents = useCallback((mainId: string | undefined) => {
    copyURL(mainId)
    setShowShare(false)
  }, [])
  const kakaoPhishingContents = useCallback(() => {
    shareKakao()
    setShowShare(false)
  }, [shareKakao])

  return (
    <PhishingButtonList>
      <ButtonPhishing btnType="shield">
        <Link to={"/join"}>금융사기 예방하기</Link>
      </ButtonPhishing>
      <ButtonPhishing btnType="share">
        <button type="button" onClick={handleShowShare}>
          공유하기
        </button>
        {showShare && (
          <div className="share_balloon">
            <button type="button" className="share_close" onClick={handleShowShare}>
              <i className="hide">close</i>
            </button>
            <div className="share_case">
              <ul>
                <li>
                  <button type="button" className="share_copy" onClick={() => copyPhishingContents(mainId)}>
                    링크복사
                  </button>
                </li>
                <li>
                  <button type="button" className="share_kakao" onClick={kakaoPhishingContents}>
                    카톡공유
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </ButtonPhishing>
    </PhishingButtonList>
  )
}

export const ScrollTopButton = () => {
  const [showTop, setShowTop] = useRecoilState(showTopState)
  const clickToTop = useCallback(() => {
    onScrollTop(true)
  }, [])

  useEffect(() => {
    const body = document.querySelector("body")
    body?.addEventListener("scroll", () => {
      if (body.scrollTop > 10) setShowTop(true)
      else setShowTop(false)
    })
  }, [setShowTop])

  return (
    <FixTopButton type="button" className={showTop ? "show" : ""} onClick={clickToTop}>
      TOP
    </FixTopButton>
  )
}

export const FixAppDown = () => {
  const setShowAppdown = useSetRecoilState(showAppdownState)
  const [appdownShow, setAppdownShow] = useState(false)

  const openAppdown = useCallback(() => {
    setShowAppdown(true)
  }, [setShowAppdown])

  useEffect(() => {
    const body = document.querySelector("body")
    body?.addEventListener("scroll", () => {
      if (body.scrollTop > 100) setAppdownShow(true)
      else setAppdownShow(false)
    })
  }, [])

  return (
    <AppDown appdownShow={appdownShow}>
      <button type="button" onClick={openAppdown}>
        앱 다운로드
      </button>
    </AppDown>
  )
}

import styled, { css, keyframes } from "styled-components"
import refresh from "assets/images/icon_refresh.png"
import step1 from "assets/images/loading_step1.png"
import step2 from "assets/images/loading_step2.png"
import step3 from "assets/images/loading_step3.png"
import step4 from "assets/images/loading_step4.png"

export const PopLayer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  * {
    touch-action: auto;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  > .pop_contents {
    overflow: hidden;
    width: 400px;
    max-width: 480px;
    padding: 28px 16px 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1;
    @media all and (max-width: 1200px) {
      padding-top: 30px;
      padding-bottom: 30px;
      max-width: none;
    }
    @media all and (max-width: 540px) {
      width: 90%;
    }
    > .app_down {
      margin: 22px 0 30px;
      padding: 28px 20px 36px;
      background-color: #f7f7f7;
      > a {
        margin-top: 25px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .title {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    @media all and (max-width: 1200px) {
      font-size: 18px;
    }
  }
`

export const PopBtnsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -6px;
  > button {
    width: auto;
    flex-basis: 150px;
    margin: 6px;
    @media all and (max-width: 1200px) {
      flex-basis: 130px;
    }
  }
`

export const InfoTitle = styled.strong`
  display: block;
  margin: 30px 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  @media all and (max-width: 1200px) {
    margin: 15px 0;
  }
`

export const CaptchaWrap = styled.div`
  margin: 24px 0;
  text-align: center;
  > div {
    margin-bottom: 14px;
    padding: 24px 16px;
    background-color: #f7f7f7;
    @media all and (max-width: 1200px) {
      margin-bottom: 8px;
    }
  }
  .alert {
    font-size: 14px;
    @media all and (max-width: 1200px) {
      font-size: 12px;
    }
  }
  .captcha {
    padding: 8px 0;
    background-color: #fff;
    img {
      width: auto;
    }
  }
  .refresh {
    margin: 19px 0;
    font-weight: 500;
    font-size: 18px;
    color: #3dc289;
    line-height: 1;
    @media all and (max-width: 1200px) {
      margin: 16px 0;
      font-size: 16px;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      position: relative;
      top: -2px;
      vertical-align: middle;
      background: url(${refresh}) no-repeat 50% 50%;
      background-size: 100% 100%;
    }
    & + section {
      margin-top: 0;
    }
  }
`

export const TermsWrap = styled.div`
  overflow: hidden;
  height: 350px;
  margin: 14px 0;
  padding: 18px 32px;
  background-color: #f7f7f7;
  @media all and (max-width: 360px) {
    padding: 18px 16px;
  }
  pre {
    overflow-y: scroll;
    height: 100%;
    letter-spacing: 0;
    line-height: 1.5;
    white-space: pre-line;
    -webkit-overflow-scrolling: touch;
  }
`

export const BankWrap = styled.div`
  overflow: hidden;
  margin: 15px 0;
  padding-top: 15px;
  border-top: 1px solid #ced6e3;
  @media all and (max-width: 540px) {
    height: 385px;
  }
  .bank_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ced6e3;
    @media all and (max-width: 540px) {
      overflow-y: scroll;
      height: 100%;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 10px;
      -webkit-overflow-scrolling: touch;
    }
  }
`

export const PhishingWrap = styled.div`
  overflow: hidden;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 1;
  @media all and (max-width: 540px) {
    width: 90%;
  }
  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 2;
    &:before,
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      margin-top: -2px;
      background-color: #fff;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .swiper-pagination {
    top: 20px;
    bottom: auto;
    font-size: 0;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      border: 1px solid #a1aab9;
      background-color: #fff;
      opacity: 0.6;
      &-active {
        opacity: 1;
      }
    }
  }
  .swiper-button-disabled {
    opacity: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    border-left: 4px solid #fff;
    border-bottom: 4px solid #fff;
    @media all and (max-width: 540px) {
      border-left-width: 2px;
      border-bottom-width: 2px;
    }
    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: 16px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .swiper-button-next {
    right: 16px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }
  .phishing_share_wrap {
    margin-top: 12px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

const loadingAnimation = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

export const PopLoading = styled.div`
  overflow: hidden;
  width: 64px;
  height: 84px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 910;
  > .img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
    animation-name: ${loadingAnimation};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    &:nth-child(1) {
      background-image: url(${step1});
    }
    &:nth-child(2) {
      background-image: url(${step2});
    }
    &:nth-child(3) {
      background-image: url(${step3});
    }
    &:nth-child(4) {
      background-image: url(${step4});
    }
  }
`

export const MainHomeWrap = styled.div`
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 1;
  @media all and (max-width: 540px) {
    width: 360px;
  }
  @media all and (max-width: 360px) {
    width: 90%;
  }
  .close_btns_wrap {
    display: flex;
    margin-top: 14px;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 540px) {
      margin-top: 10px;
    }
    .today {
      font-weight: 700;
      font-size: 18px;
      color: #fff;
    }
    .close {
      width: 24px;
      height: 24px;
      position: relative;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        background-color: #fff;
      }
      &:before {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
`

export const NoticeCotents = styled.div`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  background-color: #f7f7f7;
  padding: 18px 16px;
  margin: 40px 0;
  @media all and (max-width: 1200px) {
    margin: 15px 0;
  }
`

export const NoticeTitle = styled.strong`
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  @media all and (max-width: 1200px) {
    margin: 15px 0;
  }
`

export const MVNOPopupWrap = styled.div`
  .close-btn {
    width: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .top {
    text-align: center;

    .description {
      font-size: 20px;
      line-height: 32px;
      font-weight: 400;
      padding-bottom: 6px;
    }
    .title {
      padding-bottom: 16px;
    }
  }
  .terms-wrap {
    display: flex;
    height: 136px;
    border: 1px solid #b3b3b3;
    margin-bottom: 36px;
    overflow: hidden;
    .terms-contents {
      margin: 1px;
      padding: 12px;
      overflow-y: scroll;
      background: #fafafa;
    }
  }
  button {
    width: 100%;
  }
`

export const TermsCheckBox = styled.label<{ isChecked: boolean }>`
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 29px 20px;
    width: 69px;

    .icon {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: -35px;
        left: 20px;
        border-radius: 50%;
        border: 2px solid transparent;
        @media all and (max-width: 1200px) {
            width: 24px;
            height: 24px;
        }
        ${(props) =>
          props.isChecked
            ? css`
                border-color: #272e40;
              `
            : css`
                border-color: #ced6e3;
              `}
        &:after {
            content: "";
            display: block;
            width: 10px;
            height: 6px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -6px 0 0 -6px;
            border-left: 2px solid transparent;
            border-bottom: 2px solid transparent;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            ${(props) =>
              props.isChecked
                ? css`
                    border-color: #272e40;
                  `
                : css`
                    border-color: #ced6e3;
                  `}
        }
`

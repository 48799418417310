import { SubmitButton } from "components/ContsButton"
import { IndexList } from "./styles"

export interface IndexButtonType {
  idx: number
  text: string
  /*20230731 phil disabled 옵션 추가*/
  disabled?: boolean
}

export interface IndexButtonListType {
  indexList: IndexButtonType[]
  nowIndex: number
  isValid?: boolean
  changeIndex: (Index: number) => void
}

const IndexButtonsList = ({ indexList, nowIndex, isValid, changeIndex }: IndexButtonListType) => {
  return (
    <IndexList>
      {indexList.map((indexItem) => {
        return (
          <li key={indexItem.idx}>
            <SubmitButton
              text={indexItem.text}
              disabled={indexItem.disabled} //20230731 phil disabled 옵션 추가
              role={nowIndex === indexItem.idx ? "active" : undefined}
              clickFunc={() => changeIndex(indexItem.idx)}
              isValid={isValid}
            />
          </li>
        )
      })}
    </IndexList>
  )
}

export default IndexButtonsList

import LinklineTab from "components/LinklineTab"
import SubpageConts from "layouts/SubpageConts"
import SubpageTop from "layouts/SubpageTop"
import { Outlet } from "react-router-dom"
import { useTermsData } from "hooks/query"

const linkProps = [
  { key: 0, tabname: "서비스이용약관", link: "used" },
  { key: 1, tabname: "개인정보처리방침", link: "personal" },
]
const Terms = () => {
  const { data: TermsData } = useTermsData()
  return (
    <>
      <SubpageTop />
      <SubpageConts>
        {TermsData?.Body && (
          <>
            <LinklineTab linkArray={linkProps} />
            <Outlet context={{ TermsData }} />
          </>
        )}
      </SubpageConts>
    </>
  )
}

export default Terms

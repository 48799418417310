import styled, { css } from "styled-components"
import { ButtonRole } from "."
import shield from "assets/images/icon_shield.png"
import share from "assets/images/icon_share.png"
import copy from "assets/images/icon_copy.png"
import kakao from "assets/images/icon_kakao.png"

export const ButtonCommon = styled.button`
  padding: 14px 24px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  border: 1px solid #a1aab9;
  background-color: #fff;
  color: #3c4659;
  @media all and (max-width: 1200px) {
    padding: 12px 16px;
    font-size: 16px;
  }
`

export const ButtonSubmit = styled.button<{ role?: ButtonRole }>`
  padding: 14px 24px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  border: 1px solid transparent;
  background-color: transparent;
  @media all and (max-width: 1200px) {
    padding: 14px 8px;
    font-size: 16px;
  }
  &:disabled {
    color: #fff;
    border-color: #bfbfbf;
    background-color: #bfbfbf;
  }
  ${({ role }) =>
    role === "submit" || role === "active"
      ? css`
          color: #fff;
          border-color: #272e40;
          background-color: #272e40;
        `
      : role === "main"
      ? css`
          color: #fff;
          border-color: #3dc289;
          background-color: #3dc289;
        `
      : css`
          border-color: #f7f7f7;
          background-color: #f7f7f7;
        `}
`

export const PhishingButtonList = styled.div`
  text-align: center;
  @media all and (max-width: 1200px) {
    margin: -4px;
  }
`

export const ButtonPhishing = styled.div<{ btnType: string }>`
  display: inline-block;
  min-width: 190px;
  position: relative;
  vertical-align: middle;
  @media all and (max-width: 1200px) {
    width: 50%;
    min-width: auto;
    padding: 4px;
  }
  > a,
  > button {
    display: block;
    width: 100%;
    padding: 12px 24px;
    font-weight: 500;
    font-size: 14px;
    background-color: #fff;
    border-radius: 22px;
    border: 1px solid transparent;
    @media all and (max-width: 1200px) {
      padding: 8px 17px;
      border-radius: 17px;
    }
    ${({ btnType }) =>
      btnType === "shield"
        ? css`
            color: #3dc289;
            border-color: #3dc289;
          `
        : btnType === "share" &&
          css`
            color: #272e40;
            border-color: #272e40;
          `}
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 15px;
      margin-right: 6px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      @media all and (max-width: 1200px) {
        margin-right: 3px;
        background-size: 100% 100%;
      }
      ${({ btnType }) =>
        btnType === "shield"
          ? css`
              background-image: url(${shield});
              @media all and (max-width: 1200px) {
                width: 10px;
                height: 12px;
              }
            `
          : btnType === "share" &&
            css`
              background-image: url(${share});
              @media all and (max-width: 1200px) {
                width: 9px;
                height: 9px;
              }
            `}
    }
  }
  .share_close,
  .share_case {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }
  .share_balloon {
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    bottom: 100%;
    text-align: center;
    .share_close {
      width: 24px;
      height: 24px;
      position: relative;
      border-radius: 50%;
      background-color: #fff;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 14px;
        height: 1px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -7px;
        background-color: #3c4659;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-225deg);
      }
    }
    .share_case {
      margin: 8px 0;
      padding: 8px 12px;
      border-radius: 12px;
      background-color: #fff;
      border: 1px solid #272e40;
      @media all and (max-width: 1200px) {
        margin: 6px;
        padding: 7px 10px;
        border-radius: 10px;
      }
      > ul {
        margin: -4px;
        font-size: 0;
        text-align: center;
      }
      li {
        display: inline-block;
        width: 50%;
        padding: 4px;
        vertical-align: middle;
      }
      button {
        display: block;
        width: 100%;
        padding: 14px 0 2px;
        font-weight: 500;
        font-size: 14px;
        background-color: #f7f7f7;
        border-radius: 8px;
        @media all and (max-width: 1200px) {
          padding-top: 10px;
          font-size: 12px;
          border-radius: 6px;
        }
        &:before {
          content: "";
          display: block;
          width: 36px;
          height: 32px;
          margin: 0 auto 8px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: auto 100%;
          @media all and (max-width: 1200px) {
            margin: 0 auto 5px;
            background-size: 100% 100%;
          }
        }
      }
      .share_copy {
        &:before {
          background-image: url(${copy});
          @media all and (max-width: 1200px) {
            width: 26px;
            hieght: 26px;
          }
        }
      }
      .share_kakao {
        &:before {
          background-image: url(${kakao});
          @media all and (max-width: 1200px) {
            width: 30px;
            hieght: 27px;
          }
        }
      }
    }
  }
`

export const FixTopButton = styled.button`
  visibility: hidden;
  opacity: 0;
  width: 64px;
  height: 64px;
  position: fixed;
  right: 83px;
  bottom: 73px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 50%;
  background-color: #fff;
  color: #272e40;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  transition: visibility 0.3s ease, opacity 0.3s ease;
  z-index: 610;
  &.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s ease, opacity 0.3s ease;
  }
  @media all and (max-width: 1200px) {
    width: 44px;
    height: 44px;
    right: 16px;
    font-size: 14px;
  }
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid #272e40;
    @media all and (max-width: 1200px) {
      border-left-width: 6px;
      border-right-width: 6px;
      border-bottom-width: 10px;
    }
  }
`

export const AppDown = styled.div<{ appdownShow: boolean }>`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 600;
  transition: bottom 0.4s ease-in-out;
  -webkit-transition: bottom 0.4s ease-in-out;
  ${({ appdownShow }) =>
    appdownShow
      ? css`
          bottom: 0;
        `
      : css`
          bottom: -60px;
        `}
  > button {
    width: 100%;
    padding: 14px 24px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #fff;
    background-color: #3dc289;
  }
`

import { useCallback, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { isPcState, showAppdownState } from "store"
import { useMainPopQueryData } from "hooks/query"
import { layerClose, layerOpen, pauseTodayPop } from "service/utils"
import MainIntro from "./components/MainIntro"
import MainPhishing from "./components/MainPhishing"
import MainVideo from "./components/MainVideo"
import MainAward from "./components/MainAward"
import MainNews from "./components/MainNews"
import MainFunc from "./components/MainFunc"
import MainStop from "./components/MainStop"
import MainAsk from "./components/MainAsk"
import { FixAppDown, ScrollTopButton } from "components/ContsButton"
import { AppdownPop, MainHomePop } from "components/Popup"

const Main = () => {
  const isPc = useRecoilValue(isPcState)
  const [showAppdown, setShowAppdown] = useRecoilState(showAppdownState)
  const [mainPopFlag, setMainPopFlag] = useState(false)
  const { data: mainPopData } = useMainPopQueryData()

  const closeMainPop = useCallback(() => {
    layerClose()
    setMainPopFlag(false)
  }, [])
  const closeAppdownPop = useCallback(() => {
    layerClose()
    setShowAppdown(false)
  }, [setShowAppdown])

  useEffect(() => {
    if (pauseTodayPop()) setMainPopFlag(true)
  }, [])

  /* 20231024 lgu+ 가입/해지 불가 모달 */
  /*const [layerPopupFlag, setLayerPopupFlag] = useState(true)
  const { flag } = useNoticeModal("2023-10-16 00:00:00", "2023-10-23 08:00:00")
  const noticePopupClose = useCallback(() => {
    layerClose()
    setLayerPopupFlag(false)ㅞㅡ
  }, [setLayerPopupFlag])
  const goNotice = useCallback(() => {
    layerClose()
    setLayerPopupFlag(false)
    const index = window.location.href.indexOf("local") > 0 || window.location.href.indexOf("dev") > 0 ? 15 : 28
    navigate(`/customer/notice/${index}`)
  }, [navigate])*/

  return (
    <main className="main">
      <ScrollTopButton />
      <MainIntro />
      <MainPhishing />
      <MainVideo />
      <MainAward />
      <MainNews />
      <MainFunc />
      <MainStop />
      <MainAsk />
      {!isPc && <FixAppDown />}
      {mainPopData?.Body &&
        mainPopFlag &&
        (function () {
          layerOpen()
          return <MainHomePop mainPopData={mainPopData.Body} closeFunc={closeMainPop} />
        })()}
      {showAppdown &&
        (function () {
          layerOpen()
          return <AppdownPop closeFunc={closeAppdownPop} />
        })()}
      {/*20231024 lgu+ 가입/해지 불가 모달*/}
      {/*{flag() &&
        layerPopupFlag &&
        (function () {
          layerOpen()
          return (
            <NoticePopup
              title={`LG U+ 시스템 점검 안내`}
              contents={`
                LG U+ 이동통신사에서 진행하는 시스템 점검에 의해 LG U+ 이용 고객의 서비스 가입/해지가 일시 중단될 예정입니다.<br/><br/>
                ▣ 점검 기간<br/>
                2023년 10월 20일(금) 22:00 ~ 2023년 10월 23일(월) 08:00<br/><br/>
                자세한 내용은 공지사항 확인을 부탁 드립니다.<br/><br/>
                더 좋은 서비스로 보답하겠습니다.`}
              closeFunc={noticePopupClose}
            >
              <SubmitButton text="취소" role="submit" clickFunc={noticePopupClose} />
              <SubmitButton text="자세히보기" role="main" clickFunc={goNotice} />
            </NoticePopup>
          )
        })()}*/}
    </main>
  )
}

export default Main

import { useCallback } from "react"
import { useIndexButton, useMoreButton } from "hooks"
import { useFaqListQueryData } from "hooks/query"
import { FaqDataType } from "types"
import { CommonButton } from "components/ContsButton"
import IndexButtonsList from "components/IndexButtonsList"

const faqIndexList = [
  { idx: 0, text: "전체" },
  { idx: 1, text: "가입/해지" },
  { idx: 2, text: "서비스이용" },
  { idx: 3, text: "기타" },
]

const faqIndexKeys = {
  0: "Total",
  1: "서비스 가입 및 해지",
  2: "서비스 이용",
  3: "기타",
} as const

type faqIndexType = keyof typeof faqIndexKeys

const Faq = () => {
  const [faqIndex, onChangeFaqIndex] = useIndexButton(0)
  const [more, onChangeMore, setMore] = useMoreButton(0)
  const [toggleIndex, onChangeToggleIndex] = useIndexButton(-1)
  const { data: faqDetailData } = useFaqListQueryData(faqIndexKeys[faqIndex as faqIndexType], faqIndex)
  const faqDetailFilter = faqDetailData?.Body.LIST.filter((faqDetailItem: FaqDataType, faqDetailIndex: number) => {
    return faqDetailIndex < (more + 1) * 7 && faqDetailItem
  })
  const faqDetailSpliceData = faqDetailFilter?.splice(6 * (more + 1), more + 1)

  const onChangeFaqIndexToType = useCallback(
    (faqTabIndex: number) => {
      onChangeFaqIndex(faqTabIndex)
      onChangeToggleIndex(-1)
      setMore(0)
    },
    [onChangeFaqIndex, onChangeToggleIndex, setMore],
  )
  const onToggleItem = useCallback(
    (eleIndex: number) => {
      if (eleIndex === toggleIndex) onChangeToggleIndex(-1)
      else onChangeToggleIndex(eleIndex)
    },
    [toggleIndex, onChangeToggleIndex],
  )

  return (
    <>
      <h3 className="subconts_tit">자주묻는질문</h3>
      <IndexButtonsList indexList={faqIndexList} nowIndex={faqIndex} changeIndex={onChangeFaqIndexToType} />
      <section className="faq_wrap">
        {faqDetailFilter?.map((faqDetailItem: FaqDataType) => {
          return (
            <div key={faqDetailItem.Idx} className={`faq_item ${toggleIndex === faqDetailItem.Idx ? "show" : ""}`}>
              <div className="faq_item_q" onClick={() => onToggleItem(faqDetailItem.Idx)}>
                <button type="button">
                  <strong className="faq_tit">{faqDetailItem.Title}</strong>
                </button>
              </div>
              <div className="faq_item_a">
                <pre>{faqDetailItem.Contents}</pre>
              </div>
            </div>
          )
        })}
      </section>
      {faqDetailSpliceData?.length !== 0 && (
        <div className="cont_btns_wrap">
          <CommonButton text="더보기" clickFunc={onChangeMore} />
        </div>
      )}
    </>
  )
}

export default Faq

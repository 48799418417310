import ReactDOM from "react-dom/client"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { RecoilRoot } from "recoil"
import App from "./App"

import "react-datepicker/dist/react-datepicker.css"
import "swiper/css"
import "swiper/css/bundle"
import "reset-css"
import "styles/index.scss"

import { GOOGLE_REACPTCHA_KEY } from "service/constant"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
})

root.render(
  <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_REACPTCHA_KEY}>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
      {!(window.location.href.indexOf("localhost") < 0) && <ReactQueryDevtools />}
    </QueryClientProvider>
  </GoogleReCaptchaProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import { useCallback } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { NewPhishingDataType } from "types"
import { usePhishingQueryData } from "hooks/query"
import { CMDTYPE_NEW_PHISHING } from "service/constant"
import { kakaoSendShare } from "service/utils"
import { CommonButton, PhishingButtonWrap } from "components/ContsButton"

const NewPhishingDetail = () => {
  const navigate = useNavigate()
  const { newId } = useParams()
  const { data: newPhishingDetailData } = usePhishingQueryData(CMDTYPE_NEW_PHISHING, newId)

  const shareKakao = useCallback(() => {
    kakaoSendShare(newPhishingDetailData?.newData, "new")
  }, [newPhishingDetailData?.newData])
  const goBack = useCallback(() => {
    navigate("/phishing")
  }, [navigate])

  return (
    <>
      <section className="phishing_detail_conts">
        <div className="detail_tit">
          <span>최신피싱사례</span>
          <strong>{newPhishingDetailData?.newData.Title}</strong>
        </div>
        <div className="phishing_share_wrap break_pc_off">
          <PhishingButtonWrap shareKakao={shareKakao} />
        </div>
        <div className="detail_desc" dangerouslySetInnerHTML={{ __html: newPhishingDetailData?.newData.Contents }} />
        <div className="phishing_share_wrap">
          <PhishingButtonWrap shareKakao={shareKakao} />
        </div>
      </section>
      <section className="phishing_detail_other">
        <strong>다음 이야기들도 읽어보세요.</strong>
        <div className="phishing_recent_list">
          <ul>
            {newPhishingDetailData?.recentData.map((recentItem: NewPhishingDataType) => {
              return (
                <li key={recentItem.Idx}>
                  <Link to={`/phishing/new/${recentItem.Idx}`}>
                    <span className="img">
                      <img src={recentItem.serviceImg} alt="" />
                    </span>
                    <strong className="title">{recentItem.Title}</strong>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
      <div className="cont_btns_wrap">
        <CommonButton text="목록보기" clickFunc={goBack} />
      </div>
    </>
  )
}

export default NewPhishingDetail

import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useIndexButton, useInput, useTimer } from "hooks"
import { useGetCaptchaQueryMutate, useVerifyQueryMutate } from "hooks/query"
import { CMDTYPE_GET_CAPTCHA, CMDTYPE_SEND_CAPTCHA, CMDTYPE_SEND_VERIFY } from "service/constant"
import { deviceCheck, layerOpen, layerToggle, validInputLength, validPhoneNumber } from "service/utils"
import SubpageConts from "layouts/SubpageConts"
import SubpageTop from "layouts/SubpageTop"
import { InsertInput, InsertTel } from "components/InsertForms"
import { SubmitButton } from "components/ContsButton"
import { CaptchaPopup, InfoPopup, LoadingPop } from "components/Popup"
import { IndexButtonType } from "../../components/IndexButtonsList"

const telIndexList: IndexButtonType[] = [
  { idx: 0, text: "SKT", disabled: false },
  { idx: 1, text: "KT", disabled: false },
  { idx: 2, text: "LG U+", disabled: false },
]

const popupText = {
  3: "인증번호 3회 오류<br />인증번호를 다시 받아주세요.",
  7: "인증번호가 성공적으로 전송됐습니다.",
  8: "인증번호를 입력해 주세요.",
  10: "필수 약관에 동의 해주세요.",
  11: "인증번호 받기를 먼저 시도해 주세요.",
  12: "유효 인증시간이 초과되었습니다.<br />인증번호를 다시 받아주세요.",
  13: "정상적으로 해지처리 되었습니다.<br />이용해 주셔서 감사합니다.",
  16: "휴대폰 번호 또는 통신사를 확인하세요.",
} as const

type popupTextType = keyof typeof popupText

const validTelecomCode = (telecomCode: string | undefined) => {
  return !!telecomCode && parseInt(telecomCode) < 3 && parseInt(telecomCode) > -1
}
const Cancel = () => {
  const { telecomCode } = useParams() /* 20230731 phil disabled 옵션 추가 */
  const [telecomList, setTelecomList] = useState<IndexButtonType[]>([]) /* 20230731 phil disabled 옵션 추가 */
  const navigate = useNavigate()
  const [popIdx, onChangePopIdx] = useIndexButton(0)
  const [telIndex, onChangeTelIndex] = useIndexButton(
    validTelecomCode(telecomCode) ? parseInt(telecomCode as string) : 0,
  )
  const [phone, onChangePhone, setPhone] = useInput("", "number")
  const [captchaNumber, onChangeCaptchaNumber, setCaptchaNumber] = useInput("", "number")
  const [cert, onChangeCert, setCert] = useInput("", "number")
  const [min, sec, time, timeRef, startTimer, resetTimer] = useTimer()
  const phoneInput = useRef<HTMLInputElement>(null)
  const certInput = useRef<HTMLInputElement>(null)
  const certButtonRef = useRef<HTMLButtonElement>(null)
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const captchaButtonRef = useRef<HTMLButtonElement>(null)
  const capchaInputRef = useRef<HTMLInputElement>(null)

  const { data: captchaData, mutate: getCaptcha, reset: resetCaptcha } = useGetCaptchaQueryMutate()
  const { data: sendCaptchaData, mutate: sendCaptcha, reset: resetSendCaptcha } = useVerifyQueryMutate()
  const { isLoading, data: cancelVerofyData, mutate: cancelVerify, reset: resetCancelVerify } = useVerifyQueryMutate()
  const resetPopIdx = useCallback(() => {
    layerToggle()
    onChangePopIdx(0)
    if (popIdx === 13) navigate("/")
  }, [popIdx, onChangePopIdx, navigate])
  const resetCallback = useCallback(() => {
    onChangeTelIndex(validTelecomCode(telecomCode) ? parseInt(telecomCode as string) : 0)
    setPhone("")
    setCaptchaNumber("")
    setCert("")
    resetCaptcha()
    resetSendCaptcha()
    resetCancelVerify()
  }, [
    onChangeTelIndex,
    telecomCode,
    setPhone,
    setCaptchaNumber,
    setCert,
    resetCaptcha,
    resetSendCaptcha,
    resetCancelVerify,
  ])
  const onCaptchaSend = useCallback(() => {
    const phoneElm = phoneInput.current
    const buttonElm = certButtonRef.current
    if (phoneElm) phoneElm.blur()
    if (buttonElm) buttonElm.blur()
    getCaptcha(CMDTYPE_GET_CAPTCHA, {
      onSuccess: () => {
        setCaptchaNumber("")
        layerToggle()
        onChangePopIdx(4)
      },
    })
  }, [onChangePopIdx, setCaptchaNumber, getCaptcha])
  const onCaptchaSubmit = useCallback(() => {
    const inputEle = capchaInputRef?.current
    const buttonEle = captchaButtonRef?.current
    if (buttonEle) buttonEle.blur()
    if (inputEle) inputEle.blur()
    if (captchaNumber.length < 4) {
      alert("보안문자 입력을 확인해주세요.")
      return false
    }

    sendCaptcha(
      {
        CmdType: CMDTYPE_SEND_CAPTCHA,
        Body: {
          CaptchaTransID: captchaData.Body.TransID,
          TeleType: String(telIndex),
          PNumber: phone,
          AuthType: "delete",
          AuthToken: captchaNumber,
          Pcode: "ec9a7230-7725",
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
        },
      },
      {
        onSuccess: (data) => {
          if (data.Header.ErrCode !== 0) {
            alert(data.Header.ErrMsg)
            setCaptchaNumber("")
            switch (data.Header.ErrCode) {
              case 100401:
                resetPopIdx()
                break
              case 100301:
              case 100503:
              case 100504:
              case 900002:
              case 102501:
                resetTimer(resetCallback)
                resetPopIdx()
                break
            }
          } else {
            alert("인증번호가 성공적으로 전송됐습니다.")
            resetTimer()
            resetPopIdx()
            startTimer(data.Body.TimeOut)
            const certEle = certInput.current
            if (certEle) certEle.focus()
          }
        },
      },
    )
  }, [
    telIndex,
    phone,
    captchaNumber,
    captchaData,
    sendCaptcha,
    setCaptchaNumber,
    resetPopIdx,
    startTimer,
    resetTimer,
    resetCallback,
  ])
  const cancelVerifySubmit = useCallback(() => {
    const certEle = certInput.current
    const cancelButtonEle = cancelButtonRef.current
    if (certEle) certEle.blur()
    if (cancelButtonEle) cancelButtonEle.blur()

    if (!sendCaptchaData?.Body.TransactionID) {
      layerToggle()
      onChangePopIdx(11)
      return false
    }
    if (phone.length < 10) {
      layerToggle()
      onChangePopIdx(16)
      return false
    }
    if (cert.length < 4) {
      layerToggle()
      onChangePopIdx(8)
      return false
    }

    cancelVerify(
      {
        CmdType: CMDTYPE_SEND_VERIFY,
        Body: {
          TeleType: String(telIndex),
          PNumber: phone,
          AuthType: "delete",
          Pcode: "ec9a7230-7725",
          TransactionID: sendCaptchaData?.Body.TransactionID,
          CryptoNumber: cert,
          CALLTYPE: deviceCheck() ? "MOBILEWEB" : "PCWEB",
        },
      },
      {
        onSuccess: (data) => {
          if (data.Header.ErrCode !== 0) {
            if (data.Header.ErrCode === 100503) {
              onChangePopIdx(3)
              resetTimer(resetCallback)
            } else {
              onChangePopIdx(9)
            }
            setCert("")
          } else {
            resetTimer(resetCallback)
            onChangePopIdx(13)
          }
        },
      },
    )
  }, [sendCaptchaData, telIndex, phone, cert, onChangePopIdx, cancelVerify, setCert, resetTimer, resetCallback])

  /* 20230731 phil disabled 옵션 추가 */
  useEffect(() => {
    if (validTelecomCode(telecomCode)) {
      telIndexList.forEach((telItem) => {
        if (telItem.idx !== parseInt(telecomCode as string)) {
          telItem.disabled = true
        }
      })
    }

    setTelecomList(telIndexList)
  }, [telecomCode, telecomList])
  useEffect(() => {
    if (time.current < 0) {
      layerToggle()
      onChangePopIdx(12)
      resetTimer(resetCallback)
    }
  }, [sec, time, onChangePopIdx, resetTimer, resetCallback])
  useEffect(() => {
    let timeRefValue = 0
    if (timeRef.current) timeRefValue = timeRef.current
    return () => {
      clearInterval(timeRefValue)
    }
  }, [timeRef])

  /* 20231024 lgu+ 가입/해지 불가 모달 */
  /*
  const [layerPopupFlag, setLayerPopupFlag] = useState(true)
  const { flag } = useNoticeModal("2023-10-16 00:00:00", "2023-10-23 08:00:00")
  const noticePopupClose = useCallback(() => {
    layerClose()
    setLayerPopupFlag(false)
  }, [setLayerPopupFlag])
  const goNotice = useCallback(() => {
    layerClose()
    setLayerPopupFlag(false)
    const index = window.location.href.indexOf("local") > 0 || window.location.href.indexOf("dev") > 0 ? 15 : 28
    navigate(`/customer/notice/${index}`)
  }, [navigate])*/
  return (
    <>
      {isLoading &&
        (function () {
          layerOpen()
          return <LoadingPop />
        })()}
      <SubpageTop />
      <SubpageConts>
        <div className="subpage_info">
          <strong className="title">서비스를 해지하기 전에 아래 내용을 꼭 확인해 주세요.</strong>
          <ul>
            <li className="alert">
              청구 금액은 가입일 부터 해지일까지 일할 계산되어 청구됩니다. ( 월 1,650원 부가세포함 )
            </li>
            <li>
              [스마트피싱보호] 서비스를 해지하시면, 피싱 · 스미싱 등의 사기피해 예방이 어려워지며, 피싱사기 피해가
              발생한 후에도 금전적 손실에 대한 보상을 받으실 수 없습니다.
            </li>
          </ul>
        </div>
        <h3 className="subconts_tit">서비스 해지</h3>
        <section className="forms_wrap">
          <InsertTel
            title="통신사 선택"
            telList={telecomList}
            telIdx={telIndex}
            isValid={sendCaptchaData?.Body.TransactionID ? true : false}
            clickFunc={onChangeTelIndex}
          />
          <InsertInput
            title="휴대폰 번호"
            isValid={validPhoneNumber(phone)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={11}
            holder="‘-’ 제외 하고 입력해 주세요."
            readTo={sendCaptchaData?.Body.TransactionID ? true : false}
            Inputref={phoneInput}
            value={phone}
            changeFunc={onChangePhone}
            keyboardFunc={onCaptchaSend}
          >
            <SubmitButton
              buttonRef={certButtonRef}
              text="인증번호 받기"
              role="submit"
              isValid={!validPhoneNumber(phone)}
              clickFunc={onCaptchaSend}
            />
          </InsertInput>
          <InsertInput
            title="인증번호 입력"
            isValid={!validInputLength(cert, 4)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={4}
            holder="인증번호를 입력해주세요."
            Inputref={certInput}
            value={cert}
            changeFunc={onChangeCert}
            keyboardFunc={cancelVerifySubmit}
          >
            <span className="timer alert">
              {min}:{sec}
            </span>
          </InsertInput>
          <div className="cont_btns_wrap">
            <SubmitButton
              disabled={isLoading}
              buttonRef={cancelButtonRef}
              text="서비스 해지"
              role="submit"
              clickFunc={cancelVerifySubmit}
            />
          </div>
        </section>
      </SubpageConts>
      {popIdx === 4 && captchaData && (
        <CaptchaPopup
          title="하단 보안문자를 입력하세요."
          captchaBodyData={captchaData.Body}
          captchaReset={onCaptchaSend}
          closeFunc={resetPopIdx}
        >
          <InsertInput
            Inputref={capchaInputRef}
            isValid={!validInputLength(captchaNumber, 4)}
            type={deviceCheck() ? "tel" : "text"}
            maxLength={4}
            holder="보안문자 입력."
            value={captchaNumber}
            changeFunc={onChangeCaptchaNumber}
            keyboardFunc={onCaptchaSubmit}
          />
          <SubmitButton text="취소" role="submit" clickFunc={resetPopIdx} />
          <SubmitButton buttonRef={captchaButtonRef} text="확인" role="main" clickFunc={onCaptchaSubmit} />
        </CaptchaPopup>
      )}
      {(popIdx === 3 ||
        popIdx === 7 ||
        popIdx === 8 ||
        popIdx === 10 ||
        popIdx === 11 ||
        popIdx === 12 ||
        popIdx === 13) && (
        <InfoPopup info={popupText[popIdx as popupTextType]} closeFunc={resetPopIdx}>
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </InfoPopup>
      )}
      {popIdx === 9 && (
        <InfoPopup info={cancelVerofyData?.Header.ErrMsg} closeFunc={resetPopIdx}>
          <SubmitButton text="확인" role="submit" clickFunc={resetPopIdx} />
        </InfoPopup>
      )}
      {/*20231024 lgu+ 가입/해지 불가 모달*/}
      {/*{flag() &&
        layerPopupFlag &&
        (function () {
          layerOpen()
          return (
            <NoticePopup
              title={`LG U+ 시스템 점검 안내`}
              contents={`
                LG U+ 이동통신사에서 진행하는 시스템 점검에 의해 LG U+ 이용 고객의 서비스 해지가 일시 중단될 예정입니다.<br/><br/>
                ▣ 점검 기간<br/>
                2023년 10월 20일(금) 22:00 ~ 2023년 10월 23일(월) 08:00<br/><br/>
                자세한 내용은 공지사항 확인을 부탁 드립니다.<br/><br/>
                더 좋은 서비스로 보답하겠습니다.`}
              closeFunc={noticePopupClose}
            >
              <SubmitButton text="취소" role="submit" clickFunc={noticePopupClose} />
              <SubmitButton text="자세히보기" role="main" clickFunc={goNotice} />
            </NoticePopup>
          )
        })()}*/}
    </>
  )
}

export default Cancel

import awardPc from "assets/images/main_award_pc.png"
import awardTrophy from "assets/images/main_award_trophy.png"

const MainAward = () => {
  return (
    <section className="main_sec_wrap sec_award">
      <div className="wrap_wide">
        <section className="main_award_wrap">
          <div className="award_img">
            <span className="img">
              <img src={awardPc} alt="" />
            </span>
            <div className="award_explain">
              <strong>ANTI - SCAM</strong>
              <p>
                피싱에 이용되는 발신 번호의 유형을 수집/분석해 구축한
                <br />
                데이터베이스를 기반으로 휴대폰에 피싱의심번호로 전화가 걸려오면
                <br />
                통화 전에는 사전 경고, 통화 중에는 단계별 경고를
                <br />
                제공해 피싱피해를 예방할 수 있습니다.
              </p>
            </div>
          </div>
          <div className="award_desc">
            <i>STOP.THINK.CONNECT</i>
            <span>2021년 보이스피싱 피해액 약 7천 7백억 원*</span>
            <strong>
              치밀한 피싱사기를 막아보세요<small className="alert">*2021년 경찰청 보도자료</small>
            </strong>
          </div>
        </section>
        <section className="main_award_wrap">
          <div className="award_desc">
            <i>2021 4차 산업혁명 우수기업</i>
            <span>행정안전부</span>
            <strong>장관상수상</strong>
          </div>
          <div className="award_img">
            <span className="img">
              <img src={awardTrophy} alt="" />
            </span>
          </div>
        </section>
      </div>
    </section>
  )
}

export default MainAward

import { Link } from "react-router-dom"
import { CommonButton } from "components/ContsButton"
import { NoticeDataType } from "types"
import { useMoreButton } from "hooks"
import { useNoticeQueryData } from "hooks/query"

const Notice = () => {
  const [more, onChangeMore] = useMoreButton(0)
  const { data: noticeData } = useNoticeQueryData(7, more)
  const noticeSpliceData = noticeData?.Body.splice(6 * (more + 1), more + 1)

  return (
    <>
      <h3 className="subconts_tit">공지사항</h3>
      <section className="notice_wrap">
        {noticeData?.Body.map((noticeItem: NoticeDataType) => {
          return (
            <div className="notice_conts" key={noticeItem.Idx}>
              <Link to={`${noticeItem.Idx}`}>
                <strong className="notice_tit">{noticeItem.Title}</strong>
                <span className="notice_date">{noticeItem.RegDT_Text}</span>
              </Link>
            </div>
          )
        })}
      </section>
      {noticeSpliceData?.length !== 0 && (
        <div className="cont_btns_wrap">
          <CommonButton text="더보기" clickFunc={onChangeMore} />
        </div>
      )}
    </>
  )
}

export default Notice

import LinklineTab from "components/LinklineTab"
import SubpageConts from "layouts/SubpageConts"
import SubpageTop from "layouts/SubpageTop"
import { Outlet } from "react-router-dom"
import { isURLIncludeServiceCancel } from "service/utils"

const linkProps = [
  { key: 0, tabname: "공지사항", link: "notice" },
  { key: 1, tabname: "새소식", link: "news" },
  { key: 2, tabname: "1:1문의", link: "inquiry" },
  ...(isURLIncludeServiceCancel() ? [{ key: 3, tabname: "서비스 환불", link: "refund" }] : []),
  { key: 4, tabname: "자주묻는질문", link: "faq" },
]

const Customer = () => {
  return (
    <>
      <SubpageTop />
      <SubpageConts>
        <LinklineTab linkArray={linkProps} />
        <Outlet />
      </SubpageConts>
    </>
  )
}

export default Customer

import React, { ReactNode, useCallback } from "react"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { LOCATION_AND, LOCATION_IOS } from "service/constant"
import { decodeData } from "service/utils"
import { MainPhishingDataType, TermsDocType, MainPopDataType, GetCaptchaBodyType } from "types"
import { SubmitButton } from "components/ContsButton"
import {
  BankWrap,
  CaptchaWrap,
  InfoTitle,
  MainHomeWrap,
  PhishingWrap,
  PopBtnsWrap,
  PopLayer,
  PopLoading,
  TermsWrap,
  NoticeCotents,
  NoticeTitle,
  TermsCheckBox,
  MVNOPopupWrap,
} from "./styles"

import icoClose from "assets/images/ico_close.png"

export interface PopupDefaultType {
  children: ReactNode
}

export interface InfoPopupType extends PopupDefaultType {
  info: string
  closeFunc: () => void
}

export const InfoPopup = ({ children, info, closeFunc }: InfoPopupType) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={closeFunc}>
      <div className="pop_contents" onClick={stopPropagation}>
        <InfoTitle dangerouslySetInnerHTML={{ __html: info }} />
        <PopBtnsWrap>{children}</PopBtnsWrap>
      </div>
    </PopLayer>
  )
}

export interface CaptchaPopupType extends PopupDefaultType {
  children: ReactNode[]
  title: string
  captchaBodyData: GetCaptchaBodyType
  captchaReset: () => void
  closeFunc: () => void
}

export const CaptchaPopup = ({ children, title, captchaBodyData, captchaReset, closeFunc }: CaptchaPopupType) => {
  const buttonChild = children.filter((childrenItem, childrenIndex) => childrenIndex > 0 && childrenItem)
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={closeFunc}>
      <div className="pop_contents" onClick={stopPropagation}>
        <h4 className="title">{title}</h4>
        <CaptchaWrap>
          <div>
            <span className="captcha img">
              <img src={`data:image/png;base64,${captchaBodyData.ImgB64}`} alt="" />
            </span>
            <button className="refresh" type="button" onClick={captchaReset}>
              새로고침
            </button>
            {children[0]}
          </div>
          <i className="alert">※ 화면에 출력된 문자를 정확하게 입력 해 주세요.</i>
        </CaptchaWrap>
        <PopBtnsWrap>{buttonChild}</PopBtnsWrap>
      </div>
    </PopLayer>
  )
}

export interface TermsPopupType extends PopupDefaultType {
  termsObject: TermsDocType
  closeFunc: (termsIdx?: number) => void
}

export const TermsPop = ({ termsObject, children, closeFunc }: TermsPopupType) => {
  const handleTermsClose = useCallback(() => {
    closeFunc()
  }, [closeFunc])
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={handleTermsClose}>
      <div className="pop_contents" onClick={stopPropagation}>
        <h4 className="title">{termsObject?.title}</h4>
        <TermsWrap>
          <pre dangerouslySetInnerHTML={{ __html: termsObject?.desc as string }} />
        </TermsWrap>
        <PopBtnsWrap>{children}</PopBtnsWrap>
      </div>
    </PopLayer>
  )
}

export interface BankPopupType extends PopupDefaultType {
  bankList: {
    classname: string
    bankname: string
    tel: string
  }[]
  changeFunc: (bank: string, tel: string) => void
  closeFunc: () => void
}

export const BankPop = ({ children, bankList, changeFunc, closeFunc }: BankPopupType) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={closeFunc}>
      <div className="pop_contents" onClick={stopPropagation}>
        <h4 className="title">은행선택</h4>
        <BankWrap>
          <ul className="bank_list">
            {bankList.map((bankItem) => {
              return (
                <li key={bankItem.classname} className="bank_item">
                  <button type="button" onClick={() => changeFunc(bankItem.bankname, bankItem.tel)}>
                    <span className={`img bank ${bankItem.classname}`} />
                    <strong className="bank_name">{bankItem.bankname}</strong>
                  </button>
                </li>
              )
            })}
          </ul>
        </BankWrap>
        <PopBtnsWrap>{children}</PopBtnsWrap>
      </div>
    </PopLayer>
  )
}

export interface MainPhishingPopType extends PopupDefaultType {
  detailData: MainPhishingDataType
  closeFunc: () => void
}

export const MainPhishingPop = ({ children, detailData, closeFunc }: MainPhishingPopType) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={closeFunc}>
      <PhishingWrap onClick={stopPropagation}>
        <div className="pop_phishing_swiper">
          <button type="button" className="close" onClick={closeFunc}>
            <i className="hide">닫기</i>
          </button>
          <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }}>
            {detailData.images.map((detailImage, detailImageIdx) => {
              return (
                <SwiperSlide key={detailImageIdx}>
                  <span className="img">
                    <img src={detailImage} alt="" />
                  </span>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        {children}
      </PhishingWrap>
    </PopLayer>
  )
}

export const LoadingPop = () => {
  return (
    <PopLayer>
      <PopLoading>
        <span className="img"></span>
        <span className="img"></span>
        <span className="img"></span>
        <span className="img"></span>
      </PopLoading>
    </PopLayer>
  )
}

export interface MainPopType {
  mainPopData: MainPopDataType
  closeFunc: () => void
}

export const MainHomePop = ({ mainPopData, closeFunc }: MainPopType) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])
  const closeToday = useCallback(() => {
    const expire = Date.now() + 43200000
    localStorage.setItem("pauseToday", JSON.stringify(expire))
    closeFunc()
  }, [closeFunc])

  return (
    <PopLayer onClick={closeFunc}>
      <MainHomeWrap onClick={stopPropagation}>
        <a href={mainPopData.LinkPath} target="_blank" rel="noreferrer" className="img">
          <img src={mainPopData.ImgPath} alt={decodeData(mainPopData.PopTitle)} />
        </a>
        <div className="close_btns_wrap">
          <button type="button" className="today" onClick={closeToday}>
            오늘하루 닫기
          </button>
          <button type="button" className="close" onClick={closeFunc}>
            <i className="hide">닫기</i>
          </button>
        </div>
      </MainHomeWrap>
    </PopLayer>
  )
}

export interface AppdownPopType {
  closeFunc: () => void
}

export const AppdownPop = ({ closeFunc }: AppdownPopType) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={closeFunc}>
      <div className="pop_contents" onClick={stopPropagation}>
        <h4 className="title">앱 다운로드</h4>
        <section className="app_down">
          <a href={LOCATION_AND} target="_blank" rel="noreferrer" className="google">
            Google Play
          </a>
          <a href={LOCATION_IOS} target="_blank" rel="noreferrer" className="ios">
            App Store
          </a>
        </section>
        <PopBtnsWrap>
          <SubmitButton text="닫기" role="submit" clickFunc={closeFunc} />
        </PopBtnsWrap>
      </div>
    </PopLayer>
  )
}

export interface NoticePopupType extends PopupDefaultType {
  title: string
  contents: string
  closeFunc: () => void
}
export const NoticePopup = ({ children, title, contents, closeFunc }: NoticePopupType) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])

  return (
    <PopLayer onClick={closeFunc}>
      <div className="pop_contents" onClick={stopPropagation}>
        <NoticeTitle dangerouslySetInnerHTML={{ __html: title }} />
        <NoticeCotents dangerouslySetInnerHTML={{ __html: contents }} />
        <PopBtnsWrap>{children}</PopBtnsWrap>
      </div>
    </PopLayer>
  )
}

export const MVNOPopup = ({
  children,
  closeFunc,
  check,
  setCheck,
  title,
  contents,
}: {
  children: ReactNode
  closeFunc: () => void
  check: boolean
  setCheck: (d: boolean) => void
  title: string
  contents: string
}) => {
  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation()
  }, [])
  return (
    <PopLayer onClick={closeFunc} className="mvno-terms-popup">
      <MVNOPopupWrap className="pop_contents" onClick={stopPropagation}>
        <img src={icoClose} alt="" className="close-btn" onClick={closeFunc} />
        <div className="top">
          <h4 className="description">
            KT MVNO(알뜰폰) 추가
            <br />
            약관 동의가 필요합니다.
          </h4>
          <h4 className="title">아래 약관 확인 후 동의해주세요.</h4>
        </div>
        <div className="terms-wrap">
          <div className="check-wrap">
            <input
              type="checkbox"
              id="terms-check"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCheck(e.target.checked)
              }}
            />
            <TermsCheckBox htmlFor="terms-check" isChecked={check}>
              <pre dangerouslySetInnerHTML={{ __html: title }} />
              <i className="icon" />
            </TermsCheckBox>
          </div>
          <pre className="terms-contents" dangerouslySetInnerHTML={{ __html: contents }} />
        </div>
        {children}
      </MVNOPopupWrap>
    </PopLayer>
  )
}

import { Link } from "react-router-dom"
import { useNoticeQueryData } from "hooks/query"
import { NoticeDataType } from "types"

const MainAsk = () => {
  const { data: noticeData } = useNoticeQueryData(5, 0)

  return (
    <section className="main_sec_wrap sec_ask">
      <div className="wrap_conts">
        <div className="main_ask_wrap">
          <section className="ask_link">
            <h4>
              스마트피싱보호,
              <br />
              궁금한 점이 있으신가요?
            </h4>
            <ul className="ask_link_list">
              <li className="faq">
                <Link to={"/customer/faq"}>자주묻는질문</Link>
              </li>
              <li className="inquiry">
                <Link to={"/customer/inquiry"}>1:1문의</Link>
              </li>
            </ul>
          </section>
          <section className="ask_notice">
            <div className="ask_notice_tit">
              <strong>공지사항</strong>
              <Link to={"/customer/notice"}>더보기</Link>
            </div>
            <ol className="ask_notice_list">
              {noticeData?.Body.map((noticeItem: NoticeDataType) => {
                return (
                  <li key={noticeItem.Idx}>
                    <Link to={`/customer/notice/${noticeItem.Idx}`}>{noticeItem.Title}</Link>
                  </li>
                )
              })}
            </ol>
          </section>
        </div>
      </div>
    </section>
  )
}

export default MainAsk

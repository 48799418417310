import { useCallback, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { MainPhishingDataType, NewPhishingDataType } from "types"
import { useMainPhishingDetailQueryMutate, usePhishingListQueryData } from "hooks/query"
import { kakaoSendShare, layerToggle } from "service/utils"
import { MainPhishingPop } from "components/Popup"
import { PhishingButtonWrap } from "components/ContsButton"
import PhishingInquiryImg from "assets/images/phishing_inquiry.png"
import MoPhishingInquiryImg from "assets/images/mo_phishing_inquiry.png"

const PhishingList = () => {
  const { mainId } = useParams()
  const [{ data: newPhishingData }, { data: mainPhishingData }] = usePhishingListQueryData()
  const {
    data: mainPhishingDetail,
    mutate: mutateMainPhishingDetail,
    reset: resetMainPhishingDetail,
  } = useMainPhishingDetailQueryMutate()

  const changeMainPhishingDetail = useCallback(
    (idx: string | undefined) => {
      layerToggle()
      mutateMainPhishingDetail(idx)
    },
    [mutateMainPhishingDetail],
  )
  const clearMainPhishingDetail = useCallback(() => {
    layerToggle()
    resetMainPhishingDetail()
  }, [resetMainPhishingDetail])
  const shareKakao = useCallback(() => {
    if (mainPhishingDetail) kakaoSendShare(mainPhishingDetail, "main")
  }, [mainPhishingDetail])

  useEffect(() => {
    if (mainId) changeMainPhishingDetail(mainId)
  }, [mainId, changeMainPhishingDetail])

  return (
    <>
      <section className="phishing_wrap">
        <div className="phishing_desc">
          <strong className="title">최신피싱사례</strong>
          <span>시기별, 사회이슈별로 반복되는 주요사기 유형을 알려드립니다.</span>
        </div>
        {newPhishingData && (
          <>
            <div className="phishing_swiper new pc">
              <Swiper
                loop={true}
                slidesPerView={3}
                slidesPerGroup={3}
                spaceBetween={16}
                modules={[Navigation]}
                navigation
              >
                {newPhishingData?.Body.map((newPhishingItem: NewPhishingDataType, newPhishingItemIndex: number) => {
                  return (
                    <SwiperSlide key={newPhishingItem.Idx}>
                      <div className="phishing_slide">
                        <Link to={`/phishing/new/${newPhishingItem.Idx}`}>
                          <span className="img">
                            <img src={newPhishingItem.serviceImg} alt={newPhishingItem.Title} />
                            <span className="detail_hover">
                              <i>자세히 보기</i>
                            </span>
                          </span>
                          <strong className={`title ${newPhishingItemIndex === 0 && "new"}`}>
                            {newPhishingItem.Title}
                          </strong>
                        </Link>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div className="phishing_swiper new tablet">
              <Swiper
                loop={true}
                slidesPerView={2}
                slidesPerGroup={2}
                spaceBetween={16}
                modules={[Navigation]}
                navigation
              >
                {newPhishingData?.Body.map((newPhishingItem: NewPhishingDataType, newPhishingItemIndex: number) => {
                  return (
                    <SwiperSlide key={newPhishingItem.Idx}>
                      <div className="phishing_slide">
                        <Link to={`/phishing/new/${newPhishingItem.Idx}`}>
                          <span className="img">
                            <img src={newPhishingItem.serviceImg} alt={newPhishingItem.Title} />
                            <span className="detail_hover">
                              <i>자세히 보기</i>
                            </span>
                          </span>
                          <strong className={`title ${newPhishingItemIndex === 0 && "new"}`}>
                            {newPhishingItem.Title}
                          </strong>
                        </Link>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div className="phishing_swiper new mobile">
              <Swiper
                loop={true}
                slidesPerView={1}
                slidesPerGroup={1}
                spaceBetween={16}
                modules={[Navigation]}
                navigation
              >
                {newPhishingData?.Body.map((newPhishingItem: NewPhishingDataType, newPhishingItemIndex: number) => {
                  return (
                    <SwiperSlide key={newPhishingItem.Idx}>
                      <div className="phishing_slide">
                        <Link to={`/phishing/new/${newPhishingItem.Idx}`}>
                          <span className="img">
                            <img src={newPhishingItem.serviceImg} alt={newPhishingItem.Title} />
                            <span className="detail_hover">
                              <i>자세히 보기</i>
                            </span>
                          </span>
                          <strong className={`title ${newPhishingItemIndex === 0 && "new"}`}>
                            {newPhishingItem.Title}
                          </strong>
                        </Link>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </>
        )}
      </section>
      <section className="phishing_wrap">
        <div className="phishing_desc">
          <strong className="title">주요피싱유형</strong>
          <span>최근 빈번하게 발생하는 주요 피싱유형을 확인해 사전에 피해를 예방하세요.</span>
        </div>
        {mainPhishingData && (
          <>
            <div className="phishing_swiper main break_pc_off">
              <Swiper
                loop={true}
                slidesPerView={4}
                slidesPerGroup={4}
                spaceBetween={16}
                modules={[Navigation]}
                navigation
              >
                {mainPhishingData?.Body.map((mainPhishingItem: MainPhishingDataType) => {
                  return (
                    <SwiperSlide key={mainPhishingItem.Idx}>
                      <div className="phishing_slide">
                        <button type="button" onClick={() => changeMainPhishingDetail(mainPhishingItem.Idx)}>
                          <span className="img">
                            <img src={mainPhishingItem.CardThumbNail} alt="" />
                          </span>
                        </button>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div className="phishing_swiper main break_pc_on">
              <Swiper loop={true} spaceBetween={16} modules={[Navigation]} navigation>
                {mainPhishingData?.Body.map((mainPhishingItem: MainPhishingDataType, mainPhishingIdx: number) => {
                  if ((mainPhishingIdx + 1) % 4 === 0) {
                    const first: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx - 3]
                    const second: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx - 2]
                    const third: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx - 1]
                    const fourth: MainPhishingDataType = mainPhishingData.Body[mainPhishingIdx]

                    return (
                      <SwiperSlide key={mainPhishingItem.Idx}>
                        <div className="phishing_slide">
                          <ol className="phishing_slide_innerlist">
                            <li>
                              <button type="button" onClick={() => changeMainPhishingDetail(first.Idx)}>
                                <span className="img">
                                  <img src={first.CardThumbNail} alt="" />
                                </span>
                              </button>
                            </li>
                            <li>
                              <button type="button" onClick={() => changeMainPhishingDetail(second.Idx)}>
                                <span className="img">
                                  <img src={second.CardThumbNail} alt="" />
                                </span>
                              </button>
                            </li>
                            <li>
                              <button type="button" onClick={() => changeMainPhishingDetail(third.Idx)}>
                                <span className="img">
                                  <img src={third.CardThumbNail} alt="" />
                                </span>
                              </button>
                            </li>
                            <li>
                              <button type="button" onClick={() => changeMainPhishingDetail(fourth.Idx)}>
                                <span className="img">
                                  <img src={fourth.CardThumbNail} alt="" />
                                </span>
                              </button>
                            </li>
                          </ol>
                        </div>
                      </SwiperSlide>
                    )
                  }
                  return null
                })}
              </Swiper>
            </div>
          </>
        )}
      </section>
      <section className="phishing_wrap inquiry">
        <div className="phishing_desc">
          <strong className="title">
            금융사기 근절을 위한 사회공헌 활동으로&nbsp;
            <br className="break_mobile_off" />
            금융/보안 기관에 컨텐츠를 무상으로 제공합니다.
          </strong>
          <p>
            스마트피싱보호는 매주 2회 보이스피싱, 스미싱 등 최신 금융사기 예방 컨텐츠를 제작해 금융/보안기관에 무상으로
            제공합니다.
            <br />
            컨텐츠 API 연동으로 제휴사 APP 활성화에 기여하고 금융사기로부터 고객을 안전하게 보호하세요.
          </p>
        </div>
        <div className="phishing_inquiry">
          <span className="img">
            <img src={PhishingInquiryImg} alt="" className="break_pc_off" />
            <img src={MoPhishingInquiryImg} alt="" className="break_pc_on" />
          </span>
          <div className="phishing_inquiry_btn">
            <Link to={"/customer/inquiry"}>금융사기 피해예방 컨텐츠 도입 문의</Link>
          </div>
        </div>
      </section>
      {mainPhishingDetail && (
        <MainPhishingPop detailData={mainPhishingDetail} closeFunc={clearMainPhishingDetail}>
          <div className="phishing_share_wrap">
            <PhishingButtonWrap shareKakao={shareKakao} mainId={mainPhishingDetail.Idx} />
          </div>
        </MainPhishingPop>
      )}
    </>
  )
}

export default PhishingList

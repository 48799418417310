import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { isPcState } from "store"
import NavMobile from "./NavMobile"
import NavPc from "./NavPc"

const Header = () => {
  const isPc = useRecoilValue(isPcState)

  return (
    <header id="header">
      <div className="header_wrap">
        <h1 className="logo">
          <Link to={"/"} />
          <i className="hide">스마트피싱보호</i>
        </h1>
        {isPc ? <NavPc /> : <NavMobile />}
      </div>
    </header>
  )
}

export default Header

import { useIndexButton } from "hooks"
import {
  TERMS_USED_SKT,
  TERMS_USED_KT_PAY,
  TERMS_USED_KT_FREE,
  TERMS_USED_LG_PAY,
  TERMS_USED_LG_FREE,
} from "service/constant/terms"
import IndexButtonsList from "components/IndexButtonsList"

const usedIndexList = [
  { idx: 0, text: "SKT", desc: TERMS_USED_SKT.desc },
  { idx: 1, text: "KT(유료가입)", desc: TERMS_USED_KT_PAY.desc },
  { idx: 2, text: "KT(무료가입)", desc: TERMS_USED_KT_FREE.desc },
  { idx: 3, text: "LG U+(유료가입)", desc: TERMS_USED_LG_PAY.desc },
  { idx: 4, text: "LG U+(무료가입)", desc: TERMS_USED_LG_FREE.desc },
]

const Used = () => {
  const [usedIndex, onChangeUsedIndex] = useIndexButton(0)

  return (
    <>
      <h3 className="subconts_tit">서비스 이용약관</h3>
      <IndexButtonsList indexList={usedIndexList} nowIndex={usedIndex} changeIndex={onChangeUsedIndex} />
      <div className="used_wrap">
        <pre>{usedIndexList[usedIndex].desc}</pre>
      </div>
    </>
  )
}

export default Used

import { useIndexButton } from "hooks"
import { TERMS_PERSONAL_TEL } from "service/constant/terms"
import IndexButtonsList from "components/IndexButtonsList"
import { useOutletContext } from "react-router-dom"

const personalIndexList = [
  { idx: 0, text: "SKT", desc: TERMS_PERSONAL_TEL.desc },
  { idx: 1, text: "KT", desc: TERMS_PERSONAL_TEL.desc },
  { idx: 2, text: "LG U+", desc: TERMS_PERSONAL_TEL.desc },
]

const Personal = () => {
  const [personalIndex, onChangePersonalIndex] = useIndexButton(0)
  const termsData: any = useOutletContext()

  return (
    <>
      <h3 className="subconts_tit">개인정보처리방침</h3>
      <IndexButtonsList indexList={personalIndexList} nowIndex={personalIndex} changeIndex={onChangePersonalIndex} />
      <div className="personal_wrap">
        <pre dangerouslySetInnerHTML={{ __html: termsData.TermsData && termsData.TermsData.Body[0].Terms8 }} />
      </div>
    </>
  )
}

export default Personal

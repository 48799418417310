import styled from "styled-components"

export const LineTabWrap = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 1200px) {
    margin-bottom: 40px;
  }
  > ul {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`

export const LineTabItem = styled.li`
  flex-grow: 1;

  > a {
    display: block;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #a1aab9;
    text-decoration: none; /* 탭이 5개 넘어가면 각 li text가 침범해서 수정, */
    position: relative; /* 가상선택자를 통해 밑줄이 생기도록 함 */

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #a1aab9;
      transition: background-color 0.3s ease;
    }

    @media all and (max-width: 1200px) {
      padding: 12px 0;
      font-size: 14px;

      &::after {
        height: 2px;
      }
    }

    &.active {
      color: #272e40;

      &::after {
        background-color: #272e40;
      }
    }
  }
`

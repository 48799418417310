import styled from "styled-components"

export const IndexList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px;
  @media all and (max-width: 1200px) {
    justify-content: flex-start;
  }
  @media all and (max-width: 1200px) {
    margin: -4px;
  }
  > li {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 5px;
    @media all and (max-width: 1200px) {
      padding: 4px;
      flex-basis: 33.3%;
      flex-grow: 0;
    }
  }
  button {
    width: 100%;
    @media all and (max-width: 540px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

import { useState } from "react"
import antiscamVideo from "assets/images/antiscam_video.png"
import policeVideo from "assets/images/police_video.png"

const MainVideo = () => {
  const [tabIdx, setTabIdx] = useState(0)
  const tabData = [
    {
      idx: 0,
      title: "경찰청 보이스피싱 예방 홍보 동영상 (출처 : 대구경찰청, 대구광역시, 금융감독원)",
      url: "https://www.youtube.com/embed/ryOgRp2BPlI",
      imgSrc: policeVideo,
    },
    {
      idx: 1,
      title: "스마트피싱보호 홍보 동영상 (출처 : 데이터유니버스)",
      url: "https://youtube.com/embed/je4xYAup-2c",
      imgSrc: antiscamVideo,
    },
  ]
  return (
    <section className="main_sec_wrap sec_video">
      <div className="wrap_conts">
        <div className={"video_wrap"}>
          <section className="video_frame">
            <p>{tabData[tabIdx].title}</p>
            <iframe
              width="100%"
              height="100%"
              src={tabData[tabIdx].url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </section>
          <section className={"video_list"}>
            {tabData.map((option: { imgSrc: string; idx: number }) => (
              <img
                src={option.imgSrc}
                key={option.imgSrc}
                className={`${option.idx === tabIdx ? "active" : "default"}`}
                onClick={() => setTabIdx(option.idx)}
                alt="video_thumnail"
              />
            ))}
          </section>
        </div>
      </div>
    </section>
  )
}

export default MainVideo

import { Outlet } from "react-router-dom"
import SubpageConts from "layouts/SubpageConts"
import SubpageTop from "layouts/SubpageTop"

const Phishing = () => {
  return (
    <>
      <SubpageTop />
      <SubpageConts>
        <Outlet />
      </SubpageConts>
    </>
  )
}

export default Phishing

import { InsertFormsType } from "components/InsertForms"
import { ChangeEvent, FocusEvent, KeyboardEvent, ReactNode, RefObject, useState } from "react"
import { CheckListWrap, InsertBox, InsertInner, InsertTitle, InsertWrap } from "./styles"
import { CheckBox } from "components/CheckForms/styles"

interface InsertInputType extends InsertFormsType {
  type: string
  maxLength?: number
  holder: string
  readTo?: boolean
  Inputref?: RefObject<HTMLInputElement>
  value: string | number
  changeFunc: (e: ChangeEvent<HTMLInputElement>) => void
  blurFunc?: (e: FocusEvent<HTMLInputElement, Element>) => boolean
  keyboardFunc?: () => void
  errMsg?: string | undefined
}

interface CheckFormsType {
  checkId: string
  termsIdx?: number
  value: number
  text: string
  changeFunc: (termsIdx: number) => void
  changeTerms?: (termIdx?: number | undefined) => void
}

export const InsertInputInnerChild = ({
  children,
  title,
  isValid,
  type,
  maxLength,
  holder,
  readTo,
  Inputref,
  value,
  changeFunc,
  blurFunc,
  keyboardFunc,
  errMsg,
}: InsertInputType) => {
  const [isShowErrMsg, setIsShowErrMsg] = useState<boolean>(false)

  const keyPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isValid && keyboardFunc) {
      if (e.key === "Enter") keyboardFunc()
    }
  }
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (blurFunc) setIsShowErrMsg(blurFunc(e))
  }

  return (
    <InsertWrap>
      {title && (
        <InsertTitle isValid={isValid}>
          <strong className="title">{title}</strong>
        </InsertTitle>
      )}
      <InsertBox>
        <InsertInner isValid={isValid}>
          <input
            type={type}
            maxLength={maxLength}
            placeholder={holder}
            readOnly={readTo}
            ref={Inputref}
            value={value}
            onChange={changeFunc}
            onBlur={handleBlur}
            onKeyPress={keyPressEnter}
          />
          {isShowErrMsg && <span className="validate_check_msg">{errMsg}</span>}
          <i className="is_valid">{children}</i>
        </InsertInner>
      </InsertBox>
    </InsertWrap>
  )
}

export const CheckForms = ({ checkId, termsIdx, value, text, changeFunc, changeTerms }: CheckFormsType) => {
  return (
    <div>
      <CheckBox isAct={Boolean(value)}>
        <input
          type="checkbox"
          className="hide"
          id={checkId}
          name={checkId}
          checked={Boolean(value)}
          onChange={() => changeFunc(termsIdx as number)}
        />
        <label htmlFor={checkId}>
          <i className="icon" />
        </label>
        <span onClick={() => changeTerms?.(termsIdx)}>{text}</span>
      </CheckBox>
    </div>
  )
}

export const CheckFormsList = ({ children }: { children: ReactNode }) => {
  return <CheckListWrap>{children}</CheckListWrap>
}

import { atom } from "recoil"
import { checkDeviceWidth } from "service/utils"

export const isPcState = atom<boolean>({
  key: "isPc",
  default: checkDeviceWidth(window.innerWidth),
})

export const showTopState = atom<boolean>({
  key: "showTop",
  default: false,
})

export const showAppdownState = atom<boolean>({
  key: "appdown",
  default: false,
})
